import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { SelectByDate } from "../index";
import SelectCustomPeriod from "../../../pages/Financials/components/SelectCustomPeriod";

import { DATES_VALUE } from "../../../constants";

export const useStyles = makeStyles({
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
});

interface IProps {
  getReport: (startDate: string, endDate: string, isAllTime?: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setReport: (a: any) => void;
  setIsLoading: (a: boolean) => void;
  initialDateBy?: DATES_VALUE;
  renderDay?:
    | ((
        day: MaterialUiPickersDate,
        selectedDate: MaterialUiPickersDate,
        dayInCurrentMonth: boolean,
        dayComponent: JSX.Element
      ) => JSX.Element)
    | undefined;
  excludeDates?: DATES_VALUE[];
}

export const SelectByDateWithCustomPeriod = ({
  getReport,
  setReport,
  setIsLoading,
  initialDateBy = DATES_VALUE.today,
  renderDay,
  excludeDates = [],
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const [dateBy, setDateBy] = useState<DATES_VALUE>(initialDateBy);

  const [customPeriodStart, setCustomPeriodStart] = useState("");
  const [customPeriodEnd, setCustomPeriodEnd] = useState("");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    if (value !== DATES_VALUE.customPeriod) {
      setIsLoading(true);
      setCustomPeriodStart("");
      setCustomPeriodEnd("");
    }

    if (value === DATES_VALUE.customPeriod) {
      setReport([]);
    }

    setDateBy(value as DATES_VALUE);
  };

  return (
    <div className={classes.filterContainer}>
      <SelectByDate
        dateBy={dateBy}
        handleChange={handleChange}
        periodStart={customPeriodStart}
        periodEnd={customPeriodEnd}
        getReport={getReport}
        excludeDates={excludeDates}
      />

      {dateBy === DATES_VALUE.customPeriod && (
        <SelectCustomPeriod
          autoOk
          customPeriodStart={customPeriodStart}
          customPeriodEnd={customPeriodEnd}
          setCustomPeriodStart={setCustomPeriodStart}
          setCustomPeriodEnd={setCustomPeriodEnd}
          isActive={!!dateBy}
          renderDay={renderDay}
        />
      )}
    </div>
  );
};

export default SelectByDateWithCustomPeriod;
