import React, { useEffect } from "react";
import moment from "moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { DATE_FORMAT } from "../../../../constants";
import { StyledDatePicker } from "../../../../components/ui";

interface IProps {
  customPeriodStart: string;
  customPeriodEnd: string;
  setCustomPeriodStart: (a: string) => void;
  setCustomPeriodEnd: (a: string) => void;
  isActive: boolean;
  autoOk?: boolean;
  renderDay?:
    | ((
        day: MaterialUiPickersDate,
        selectedDate: MaterialUiPickersDate,
        dayInCurrentMonth: boolean,
        dayComponent: JSX.Element
      ) => JSX.Element)
    | undefined;
}

const SelectCustomPeriod = ({
  customPeriodStart,
  customPeriodEnd,
  setCustomPeriodStart,
  setCustomPeriodEnd,
  isActive,
  ...rest
}: IProps): React.ReactElement => {
  const handleChangeCustomPeriodStart = (date: MaterialUiPickersDate) => {
    if (!date) {
      setCustomPeriodStart("");
      return;
    }
    if (date.isValid()) {
      setCustomPeriodStart(date.format(DATE_FORMAT));
    }
  };

  const handleChangeCustomPeriodEnd = (date: MaterialUiPickersDate) => {
    if (!date) {
      setCustomPeriodEnd("");
      return;
    }
    if (date.isValid()) {
      setCustomPeriodEnd(date.format(DATE_FORMAT));
    }
  };

  useEffect(() => {
    if (isActive) {
      handleChangeCustomPeriodStart(null);
      handleChangeCustomPeriodStart(null);
    }
  }, [isActive]);

  return (
    <>
      <StyledDatePicker
        label="Begin"
        name="Begin"
        value={customPeriodStart || null}
        onChange={handleChangeCustomPeriodStart}
        maxDate={new Date()}
        {...rest}
      />
      <StyledDatePicker
        disabled={!customPeriodStart}
        label="End"
        name="End"
        value={customPeriodEnd || null}
        minDate={moment(customPeriodStart)}
        maxDate={new Date()}
        onChange={handleChangeCustomPeriodEnd}
        clearable
        {...rest}
      />
    </>
  );
};

export default SelectCustomPeriod;
