import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tableContainer: {
    overflowX: "scroll",
  },
  bottomControls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
    marginTop: 5,
  },
  foundUsers: {
    margin: "5px 0",
  },
  filtersContainer: {
    marginTop: 9,
    display: "grid",
    gridTemplateColumns: "2fr 2fr",
  },
  table: {
    "& tr:nth-child(even) td": {
      backgroundColor: "inherit",
    },
  },
});

export default useStyles;
