// @ts-ignore
import Papa from "papaparse";
import { IGetRithmicHistoricalDataResponse } from "../../api/rithmic/index.types";

interface CSVRow {
  "Server Process Date": string;
  "Server Timestamp": string;
  [key: string]: string;
}

export const processAndDownloadCSV = (
  response: IGetRithmicHistoricalDataResponse,
  startDate: string,
  endDate: string,
  setProgress: (progress: number) => void,
  setIsLoading: (isLoading: boolean) => void
): void => {
  // @ts-ignore
  Papa.parse(response.data, {
    header: true,
    complete: (csvData: { data: CSVRow[] }) => {
      const sortedData = csvData.data.sort((a: CSVRow, b: CSVRow) => {
        const dateA: Date = new Date(a["Server Process Date"] + " " + a["Server Timestamp"]);
        const dateB: Date = new Date(b["Server Process Date"] + " " + b["Server Timestamp"]);
        return dateA.getTime() - dateB.getTime();
      });

      const csvStringSorted = Papa.unparse(sortedData);

      const url = window.URL.createObjectURL(new Blob([csvStringSorted]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `rithmic_helios_historical_data_${startDate}_${endDate}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();

      setProgress(0);
      setIsLoading(false);
    },
  });
};
