import { LineOptions } from "chart.js";

import { COLOR_PALETTE } from "../../../../constants";
import { skipped } from "../../../../components/ui/ChartLine";
import { SalesByPeriodPerCountry } from "../../../../api/billing/index.types";

export interface ICountriesToShowOption {
  value: string;
  label: string;
}

export interface IDataset {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  segment: Partial<LineOptions["segment"]>;
  spanGaps: boolean;
}

export interface ITypePurchase {
  isShowPurchase: boolean;
  isShowRebill: boolean;
  isShowResets: boolean;
  isShowFreeResets: boolean;
}

export const getDataSet = (
  data: SalesByPeriodPerCountry[],
  countriesToShow: ICountriesToShowOption[],
  labels: string[]
): IDataset[] => {
  const datasets: IDataset[] = [];

  data.forEach((d) => {
    const salesToShowIndex = countriesToShow.findIndex(({ value }) => value === d.name);
    if (salesToShowIndex === -1) {
      return;
    }
    const { date, name, count } = d;

    const dateIndex = labels.indexOf(date);
    const index = datasets.findIndex((d) => d.label === name);

    if (index !== -1) {
      datasets[index].data.push(count);
      datasets[index].data[dateIndex] = count;
      datasets[index].backgroundColor = COLOR_PALETTE[salesToShowIndex + 10];
      datasets[index].borderColor = COLOR_PALETTE[salesToShowIndex + 10];
    } else {
      const data = new Array(labels.length).fill(null);
      data[dateIndex] = count;
      datasets.push({
        label: name,
        data,
        borderColor: COLOR_PALETTE[salesToShowIndex + 10],
        backgroundColor: COLOR_PALETTE[salesToShowIndex + 10],
        tension: 0.2,
        segment: {
          // @ts-ignore
          borderColor: (ctx) => skipped(ctx, salesToShowIndex),
          // @ts-ignore
          borderDash: (ctx) => skipped(ctx, [2, 2]),
        },
        spanGaps: true,
      });
    }
  });

  return datasets;
};
