import httpClient from "../apiClient";
import {
  TGetRithmicDataRequest,
  TGetRithmicUserProductsRequest,
  TGetRithmicPastPositionsRequest,
  TGetRithmicUserBalanceOverTimeRequest,
  TGetRithmicAccountsData,
  TGetRithmicAccountsByCustomer,
  TUploadRithmicHistoricalDataFilesRequest,
  TGetRithmicAvailableHistoricalDataRequest,
  TGetRithmicHistoricalDataRequest,
} from "./index.types";

export const RITHMIC_API_URLS = {
  rithmic_accounts: "/rithmic/accounts",
  rithmic_historical_data: "/rithmic/upload-historical-data-files",
  get_rithmic_available_historical_data: "/rithmic/get-available-historical-data",
  download_rithmic_historical_data: "/rithmic/download-historical-data",
} as const;

export class RithmicAPI {
  static getRithmicDataRequest: TGetRithmicDataRequest = (gauntlet_account_name, body) =>
    httpClient.post(`rithmic/${gauntlet_account_name}`, body);
  static getRithmicUserProductsRequest: TGetRithmicUserProductsRequest = (gauntlet_account_name) =>
    httpClient.post(`rithmic/user-products/${gauntlet_account_name}`);
  static getRithmicPastPositionsRequest: TGetRithmicPastPositionsRequest = (
    gauntlet_account_name,
    body
  ) => httpClient.post(`rithmic/past-positions/${gauntlet_account_name}`, body);
  static getRithmicUserBalanceOverTimeRequest: TGetRithmicUserBalanceOverTimeRequest = (
    gauntlet_account_name,
    symbol
  ) => httpClient.post(`rithmic/user-balance-over-time/${gauntlet_account_name}`, { symbol });
  static getRithmicAccountsData: TGetRithmicAccountsData = (data, signal) =>
    httpClient.post(RITHMIC_API_URLS.rithmic_accounts, data, { signal });
  static getRithmicAccountsByCustomer: TGetRithmicAccountsByCustomer = (email) =>
    httpClient.post(`${RITHMIC_API_URLS.rithmic_accounts}/${email}`);
  static uploadRithmicHistoricalDataFilesRequest: TUploadRithmicHistoricalDataFilesRequest = (
    data
  ) =>
    httpClient.post(RITHMIC_API_URLS.rithmic_historical_data, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  static getRithmicAvailableHistoricalDataRequest: TGetRithmicAvailableHistoricalDataRequest = () =>
    httpClient.get(RITHMIC_API_URLS.get_rithmic_available_historical_data);
  static getRithmicHistoricalDataRequest: TGetRithmicHistoricalDataRequest = (query, options) =>
    httpClient.get(`${RITHMIC_API_URLS.download_rithmic_historical_data}?${query}`, {
      responseType: "blob",
      ...options,
    });
}
