import React, { useEffect, useState } from "react";

import { COLOR_PALETTE } from "../../../../../constants";
import BarChart from "../../../../../components/ui/ChartBar";
import { IReport } from "../../../../../api/billing/index.types";
import { IntlFormatUSD } from "../../../../../utils";

export interface IDatasets {
  label: string;
  data: number[];
  backgroundColor: string[] | string;
  stack?: number;
  gauntletValueName?: string;
}

interface IProps {
  report: IReport[];
  type: string;
}

export interface IBarData {
  datasets: IDatasets[];
  labels: string[];
}
const Bar = ({ report, type }: IProps): React.ReactElement => {
  const [barData, setBarData] = useState<IBarData>({
    datasets: [],
    labels: [],
  });

  const isTypeSum = type === "sum";
  const isTypePurchased = type === "purchased";
  const isTypeRefunded = type === "refunded";
  const isTypeChargeback = type === "chargeback";
  const isShowTotalWithReset = isTypeRefunded || isTypeChargeback;

  useEffect(() => {
    let resetsSum = 0;

    const labels: string[] = [];
    const datasets: IDatasets[] = [];
    const chartData: IDatasets = {
      label: "Value",
      data: [],
      backgroundColor: COLOR_PALETTE,
    };
    report.forEach(({ p_name, bh_count_purchase, sum_price_usd_resets, sum_price_usd }) => {
      labels.push(p_name || "n/a");

      if (sum_price_usd_resets) {
        resetsSum += +sum_price_usd_resets / 100;
      }

      chartData.data.push(
        ...[
          ...(isTypeSum || isShowTotalWithReset ? [+sum_price_usd / 100] : []),
          ...(isTypePurchased ? [+bh_count_purchase] : []),
        ]
      );
    });

    if (resetsSum) {
      chartData.data.push(resetsSum);
      labels.push("Resets");
    }

    datasets.push(chartData);
    setBarData({ labels, datasets });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <BarChart
      // @ts-ignore
      barData={barData}
      externalOptions={{
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            formatter(value: number) {
              return IntlFormatUSD(value, { maximumSignificantDigits: 6 });
            },
          },
        },
      }}
    />
  );
};

export default Bar;
