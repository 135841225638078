import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { useQuery } from "@tanstack/react-query";

import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import { buildColumns, IReport, buildReport } from "../../utils";
import Table from "../../../../ComplianceSearch/components/Table";
import { PLANS_NAMES_MAP } from "../../../constants";
import useStyles from "../useStyles";
import { BILLING_API_URLS } from "../../../../../api/billing";
import { FinancialsActions } from "../../../../../reducers/financialsReducer/actions";
import { BillingAPI } from "../../../../../api/billing";

import FiltersByDateAndTypes, { useSearchValues } from "../../FiltersByDateAndTypes";

const PerTifRegistration: React.FC = () => {
  const classes = useStyles();
  const { data, loading } = useSelector(
    (state) => state.financials.withdrawal_per_tif_registration
  );
  const { tifRegistration, w80 } = data;
  const { values, handlers } = useSearchValues({ initialTimeFrame: "week" });
  const dispatch = useDispatch();

  const [columns, setColumns] = useState<IColum[]>([
    {
      by: "plan",
      title: "Plan",
      path: "plan",
    },
  ]);
  const [report, setReport] = useState<IReport[]>([]);

  useQuery({
    enabled: !!values.startDate || !!values.endDate,
    queryKey: [
      BILLING_API_URLS.withdrawalRates,
      "withdrawal_per_tif_registration",
      values.startDate,
      values.endDate,
      values.isAllTime,
      values.timeFrame,
      values.typeAccount,
    ],
    async queryFn({ signal }) {
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerTIFRegistrationRequest());
      const query = new URLSearchParams();
      query.append("startDate", values.startDate);
      query.append("endDate", values.endDate);
      query.append("isAllTime", String(values.isAllTime));
      query.append("timeFrame", values.timeFrame);
      query.append("account_type", values.typeAccount);

      const response = await BillingAPI.getWithdrawalRatesRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerTIFRegistrationSuccess(response.data));
      return response;
    },
    onError() {
      console.error("Error fetching withdrawal per TIF registration");
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerTIFRegistrationFailed());
    },
  });

  useEffect(() => {
    const dates = Array.from(new Set(tifRegistration.map(({ period }) => period)));
    setColumns(
      buildColumns({
        dates,
        reportType: "amount",
        isUseChangeToPrev: false,
      })
    );

    const reportWithWithdawalPerRequest = w80.map((report) => {
      const selectedPeriodIndex = dates.findIndex((date) => date === report.period);
      const slippagePeriod = selectedPeriodIndex + values.slippagePeriod;

      const tifRegistrationData = tifRegistration.find(({ period, evaluation_completed }) => {
        if (values.slippagePeriod) {
          return (
            period === dates[slippagePeriod] &&
            PLANS_NAMES_MAP[report.account_type as keyof typeof PLANS_NAMES_MAP] ===
              evaluation_completed
          );
        }
        return (
          period === report.period &&
          PLANS_NAMES_MAP[report.account_type as keyof typeof PLANS_NAMES_MAP] ===
            evaluation_completed
        );
      });
      return {
        ...report,
        account_size: "",
        total_amount: tifRegistrationData
          ? report.total_amount / tifRegistrationData.total_count
          : 0,
      };
    });

    setReport(
      buildReport({ data: reportWithWithdawalPerRequest, dates, options: { isShowTotal: false } })
    );
  }, [data, values.slippagePeriod]);

  return (
    <div>
      <h3 className={classes.tableNameTitle}>Withdrawal per TIF registration</h3>

      <div className={cx(classes.container, classes.tableNameTitle)}>
        <FiltersByDateAndTypes
          timeFrame={values.timeFrame}
          typeAccount={values.typeAccount}
          slippagePeriod={values.slippagePeriod}
          onTimeFrameChange={handlers.handleChangeTimeFrame}
          onTypeAccountChange={handlers.handleChangeTypeAccount}
          onDateChange={handlers.handleDateChange}
          handleChangeSlippagePeriod={handlers.handleChangeSlippagePeriod}
        />
      </div>

      <Table
        csv_table_name="Withdrawal per TIF registration"
        columns={columns}
        loading={loading}
        data={report}
        extractKey={(row) => row.plan}
      />
    </div>
  );
};

export default PerTifRegistration;
