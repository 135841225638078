import httpClient from "../apiClient";
import * as Types from "./index.types";
export const DISCOUNT_API_URLS = {
  customer_discounts: "/discounts/customerDiscounts",
  discounts: "discounts",
  discount_codes: "discounts/all",
  discount_codes_report: "billing/getDiscountCodesReport",
  emails_list: "discounts/emails/link",
  batch_create: "discountsBatchCustomers",
  discount_logs: "/discounts/log_changes",
  discounts_plans: "discounts/plans",
  activate_discount_code: "discounts/activate",
  search_discount_codes_for_plan: "/discounts/search-codes-for-plan",
} as const;

export class DiscountsAPI {
  static updateDiscountCodeRequest: Types.TUpdateDiscountCodeRequest = (data) =>
    httpClient.put(DISCOUNT_API_URLS.discounts, data);
  static getDiscountCodesRequest: Types.TGetDiscountCodesRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.discount_codes, data);
  static getCustomerDiscountCodesRequest: Types.TGetCustomerDiscountCodesRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.customer_discounts, data);
  static getDiscountCodesReportRequest: Types.TGetDiscountCodesReportRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.discount_codes_report, data);
  static saveDiscountCodesRequest: Types.TSaveDiscountCodesRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.discounts, data);
  static getEmailsListRequest: Types.TGetEmailsListRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.emails_list, data);
  static createBatchCodesRequest: Types.TCreateBatchCodesRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.batch_create, data);
  static getDiscountCodesByUsedUserRequest: Types.TGetDiscountCodesByUsedUserRequest = (data) =>
    httpClient.post(DISCOUNT_API_URLS.discounts_plans, data);
  static getDiscountCodesLogChanges: Types.TGetDiscountCodesLogChanges = (data) =>
    httpClient.post(DISCOUNT_API_URLS.discount_logs, data);
  static discountCodeActivateRequest: Types.TDiscountCodeActivateRequest = (data) =>
    httpClient.put(DISCOUNT_API_URLS.activate_discount_code, data);
  static searchDiscountCodesForPlanRequest: Types.TSearchDiscountCodesForPlanRequest = (
    query,
    signal
  ) => httpClient.get(`${DISCOUNT_API_URLS.search_discount_codes_for_plan}?${query}`, { signal });
}
