import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import { FinancialsActions } from "../../../../reducers/financialsReducer/actions";
import { BILLING_API_URLS, BillingAPI } from "../../../../api/billing";
import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import Table from "../../../ComplianceSearch/components/Table";
import { IReport, buildColumns, buildReport } from "../utils";
import FiltersByDateAndTypes, { useSearchValues } from "../FiltersByDateAndTypes";

const WithdrawalStats: React.FC = () => {
  const dispatch = useDispatch();
  const { withdrawal_stats } = useSelector((state) => state.financials);
  const { values, handlers } = useSearchValues();

  const [columns, setColumns] = useState<IColum[]>([]);
  const [report, setReport] = useState<IReport[]>([]);

  useQuery({
    enabled: !!values.startDate || !!values.endDate,
    queryKey: [
      BILLING_API_URLS.withdrawalStats,
      values.startDate,
      values.endDate,
      values.isAllTime,
      values.timeFrame,
      values.typeAccount,
      values.reportType,
    ],
    queryFn: async ({ signal }) => {
      dispatch(FinancialsActions.fetchFinancialsWithdrawalStatsRequest());
      const query = new URLSearchParams();
      query.append("startDate", values.startDate);
      query.append("endDate", values.endDate);
      query.append("isAllTime", String(values.isAllTime));
      query.append("timeFrame", values.timeFrame);
      query.append("account_type", values.typeAccount);
      query.append("reportType", values.reportType);

      const report = await BillingAPI.getWithdrawalStatsRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsWithdrawalStatsSuccess(report.data.report));
      return report;
    },
    onError: () => {
      dispatch(FinancialsActions.fetchFinancialsWithdrawalStatsFailed());
    },
  });

  useEffect(() => {
    const dates = Array.from(new Set(withdrawal_stats.data.map((data) => data.period)));
    setColumns(buildColumns({ dates, reportType: values.reportType }));
    setReport(buildReport({ data: withdrawal_stats.data, dates }));
  }, [withdrawal_stats]);

  return (
    <div>
      <h2>Withdrawal stats</h2>
      <FiltersByDateAndTypes
        timeFrame={values.timeFrame}
        typeAccount={values.typeAccount}
        reportType={values.reportType}
        onTimeFrameChange={handlers.handleChangeTimeFrame}
        onTypeAccountChange={handlers.handleChangeTypeAccount}
        onReportTypeChange={handlers.handleChangeReportType}
        onDateChange={handlers.handleDateChange}
      />

      <div>
        <Table
          csv_table_name="Withdrawal stats"
          columns={columns}
          loading={withdrawal_stats.loading}
          data={report}
          extractKey={(row) => row.plan}
        />
      </div>
    </div>
  );
};

export default WithdrawalStats;
