import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  filtersByPurchaseTypeContainer: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr",
  },
  exportCsvContainer: {
    display: "flex",
  },
  exportCsvButton: {
    marginLeft: "10px",
  },
});

export default useStyles;
