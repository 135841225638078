import React, { createContext, useContext, useEffect, useState } from "react";

import { TSortParams, TBy } from "../../reducers/customersReducer";
import usePagination from "../../hooks/usePagination";

export interface SearchCustomersContextProps {
  search: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [key in TBy]?: any;
  };
  page: number;
  limit: string;
  relatedTables: string[];
  isShowActiveSubscription: boolean;
  setSearch: (search: {
    country?: string;
    note?: string;
    total?: number;
    compare_total?: string;
    flag?: string;
    joined_after?: string | null;
    joined_before?: string | null;
    expires_after?: string | null;
    expires_before?: string | null;
    name?: string;
    id?: number;
    email?: string;
    plan_name?: string;
    status?: string;
    primaryLanguage?: string;
  }) => void;
  isShowLeads: boolean;
  setPage: (page: number) => void;
  setLimit: (limit: string) => void;
  setRelatedTables: (tableName: string[]) => void;
  sortBy: TSortParams;
  setSortBy: () => void;
  setIsShowActiveSubscription: (a: boolean) => void;
  setIsShowLeads: (a: boolean) => void;
  isShowInactiveAccounts: boolean;
  setShowInactiveAccounts: (a: boolean) => void;
  isShowPassedEvaluations: boolean;
  setIsShowPassedEvaluations: (a: boolean) => void;
}

interface Props {
  children: React.ReactNode;
}

const SearchContext = createContext<SearchCustomersContextProps>({
  search: {},
  page: 0,
  limit: "25",
  relatedTables: [],
  isShowActiveSubscription: false,
  isShowLeads: false,
  setPage: () => ({}),
  setLimit: () => ({}),
  setRelatedTables: () => ({}),
  sortBy: {
    by: "id",
    order: false,
  },
  setIsShowActiveSubscription: () => ({}),
  setIsShowLeads: () => ({}),
  isShowInactiveAccounts: false,
  setShowInactiveAccounts: () => ({}),
  setSortBy: () => ({}),
  setSearch: () => null,
  isShowPassedEvaluations: false,
  setIsShowPassedEvaluations: () => ({}),
});

const SearchCustomersProvider = ({ children }: Props): React.ReactElement => {
  const [search, setSearch] = useState({
    expires: null,
    joined_after: null,
    joined_before: null,
    expires_after: null,
    expires_before: null,
    primaryLanguage: "all",
  });
  const { page, setPage, limit, setLimit } = usePagination();
  const [relatedTables, setRelatedTables] = useState<string[]>(["last4", "phone"]);
  const [sortBy, setSortBy] = useState<TSortParams>({
    by: "id",
    order: false,
  });
  const [isShowActiveSubscription, setIsShowActiveSubscription] = useState<boolean>(true);
  const [isShowLeads, setIsShowLeads] = useState<boolean>(true);
  const [isShowInactiveAccounts, setShowInactiveAccounts] = useState<boolean>(true);
  const [isShowPassedEvaluations, setIsShowPassedEvaluations] = useState<boolean>(true);

  useEffect(() => {
    setPage(0);
  }, [search]);

  return (
    <SearchContext.Provider
      value={{
        search,
        page,
        limit,
        relatedTables,
        sortBy,
        isShowActiveSubscription,
        isShowLeads,
        setPage,
        // @ts-ignore
        setSearch,
        setLimit,
        setRelatedTables,
        // @ts-ignore
        setSortBy,
        setIsShowActiveSubscription,
        setIsShowLeads,
        isShowInactiveAccounts,
        setShowInactiveAccounts,
        isShowPassedEvaluations,
        setIsShowPassedEvaluations,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchCustomersProvider;
export const useSearchCustomersContext = (): SearchCustomersContextProps =>
  useContext(SearchContext);
