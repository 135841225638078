import React, { useState } from "react";
import { Button, CircularProgress as MUIProgress } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import cx from "classnames";

import { RITHMIC_API_URLS, RithmicAPI } from "../../api/rithmic";
import useGeneralStyles from "../../useGeneralStyles";
import { DATE_FORMAT, DATES_VALUE } from "../../constants";
import SelectByDateWithCustomPeriod from "../../components/ui/SelectByDateWithCustomPeriod";
import useStyles from "./useStyles";
import { processAndDownloadCSV } from "./utils";
import UploadHistoryData from "./components/UploadHistoryData";

const RithmicHistoricalOrders = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const [availableHistoricalDates, setAvailableHistoricalDates] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useQuery({
    queryKey: [RITHMIC_API_URLS.get_rithmic_available_historical_data],
    queryFn: async () => {
      const resposne = await RithmicAPI.getRithmicAvailableHistoricalDataRequest();
      setAvailableHistoricalDates(resposne.data.availableHistoricalDates);
      return resposne;
    },
  });

  const getReport = async (startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const downloadHistoricalData = async (startDate: string, endDate: string) => {
    setProgress(0);
    setIsLoading(true);

    const query = new URLSearchParams({
      startDate,
      endDate,
    });

    RithmicAPI.getRithmicHistoricalDataRequest(query.toString(), {
      onDownloadProgress: (progressEvent) => {
        setProgress(progressEvent.loaded);
      },
    })
      .then((response) =>
        processAndDownloadCSV(response, startDate, endDate, setProgress, setIsLoading)
      )
      .catch((error) => console.error("Download error:", error));
  };

  return (
    <div className={generalClasses.containerBg}>
      <h2>Rithmic Historical Orders</h2>

      <div className={classes.filterContainer}>
        <SelectByDateWithCustomPeriod
          excludeDates={[DATES_VALUE.today]}
          initialDateBy={DATES_VALUE.yesterday}
          getReport={getReport}
          setIsLoading={() => void null}
          setReport={() => void null}
          renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
            const renderDay = day?.format(DATE_FORMAT);
            const isFutureDay = day?.isAfter();
            const isToday = day?.isSame(new Date(), "day");
            const isAvailableDay = availableHistoricalDates.includes(renderDay as string);
            return (
              <span
                className={cx({
                  [classes.missedDay]: !isAvailableDay,
                  [classes.futureDay]: isFutureDay || isToday,
                })}
              >
                {dayComponent}
              </span>
            );
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => downloadHistoricalData(startDate, endDate)}
          endIcon={isLoading && <MUIProgress color="inherit" size={20} />}
          disabled={isLoading}
          className={classes.downloadButton}
        >
          Download {!!progress && <>{Math.round(progress / 1024)} kb</>}
        </Button>
      </div>

      <UploadHistoryData />
    </div>
  );
};

export default RithmicHistoricalOrders;
