import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  flexBox: {
    display: "flex",
  },
  deductionContainer: {
    display: "flex",
    alignItems: "center",
  },
  rateContainer: {
    width: 125,
    marginLeft: 10,
  },
  rateWithdrawalAmount: {
    width: 200,
    marginLeft: 10,
  },
  container: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default useStyles;
