import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { BillingAPI, BILLING_API_URLS } from "../../../../api/billing";
// import SelectByTimeFrame, { TTimeFrame } from "../../../../components/ui/SelectByTimeFrame";
import SelectByLiveSimOrLive, {
  TTypeAccount,
} from "../../../../components/ui/SelectByLiveSimOrLive";
import { DATES_VALUE } from "../../../../constants";
import useStyles from "./useStyles";
import SelectByDateWithCustomPeriod from "../../../../components/ui/SelectByDateWithCustomPeriod";
import { FinancialsActions } from "../../../../reducers/financialsReducer/actions";
import Table from "../../../ComplianceSearch/components/Table";
import usePagination from "../../../../hooks/usePagination";
import newSortBy from "../../../../utils/newSortBy";
import { ISortBy } from "../../../../types";
import { COLUMNS } from "./constants";

const TIFRegistrationByLocation: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pagination = usePagination();

  const { data, loading } = useSelector((state) => state.financials.tif_registration_by_location);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllTime, setIsAllTime] = useState(false);
  // const [timeFrame, setTimeFrame] = useState<TTimeFrame>("day");
  const [typeAccount, setTypeAccount] = useState<TTypeAccount>("all");
  const [sortBy, setSortBy] = useState<ISortBy>({ by: "counrty_name", order: true });

  useQuery({
    enabled: !!startDate || !!endDate,
    queryKey: [
      BILLING_API_URLS.tifRegistrationByLocation,
      startDate,
      endDate,
      isAllTime,
      // timeFrame,
      typeAccount,
      sortBy,
      pagination.page,
      pagination.limit,
    ],
    queryFn: async ({ signal }) => {
      dispatch(FinancialsActions.fetchFinancialsTIFRegistrationByLocationRequest());
      const query = new URLSearchParams();
      query.append("startDate", startDate);
      query.append("endDate", endDate);
      query.append("isAllTime", String(isAllTime));
      // query.append("timeFrame", timeFrame);
      query.append("account_type", typeAccount);
      query.append("sortBy", sortBy.by);
      query.append("order", sortBy.order ? "ASC" : "DESC");
      query.append("page", String(pagination.page));
      query.append("limit", String(pagination.limit));

      const response = await BillingAPI.getTIFRegistrationByLocationRequest(
        query.toString(),
        signal
      );
      pagination.setCount(response.data.report[0].full_count);
      dispatch(
        FinancialsActions.fetchFinancialsTIFRegistrationByLocationSuccess(response.data.report)
      );

      return response;
    },
    onError(error) {
      dispatch(FinancialsActions.fetchFinancialsTIFRegistrationByLocationFailed());
      console.error("Error getting TIF registration by location", error);
    },
  });

  // const handleChangeTimeFrame = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setTimeFrame(event.target.value as TTimeFrame);
  // };
  const handleChangeTypeAccount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTypeAccount(event.target.value as TTypeAccount);
  };

  const handleChangePage = (page: number) => pagination.setPage(page);
  const handleSortBy = (by: string) => newSortBy({ by, sortBy, setSortBy });

  return (
    <div>
      <h2>TIF registration by location</h2>
      <div className={classes.container}>
        <div>
          <SelectByDateWithCustomPeriod
            setIsLoading={() => void null}
            getReport={(startDate, endDate, isAllTime) => {
              setStartDate(startDate);
              setEndDate(endDate);
              setIsAllTime(!!isAllTime);
            }}
            setReport={() => void null}
            initialDateBy={DATES_VALUE.thisWeek}
          />
        </div>

        {/* <div className={classes.timeFrameContainer}>
          <SelectByTimeFrame value={timeFrame} onChange={handleChangeTimeFrame} />
        </div> */}
        <div className={classes.accountTypeContainer}>
          <SelectByLiveSimOrLive value={typeAccount} onChange={handleChangeTypeAccount} />
        </div>
      </div>

      <Table
        csv_table_name="TIF registration by location"
        columns={COLUMNS}
        data={data}
        loading={loading}
        extractKey={(data) => data.counrty_name}
        handleChangePage={handleChangePage}
        pagination={pagination}
        handleSortBy={handleSortBy}
        sortBy={sortBy}
      />
    </div>
  );
};

export default TIFRegistrationByLocation;
