import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";

export const COLUMNS: IColum[] = [
  {
    by: "counrty_name",
    title: "Country",
    path: "counrty_name",
    isSortable: true,
  },
  {
    by: "sales_cases",
    title: "Sales cases",
    path: "sales_cases",
    isSortable: true,
  },
  {
    by: "tif_cases",
    title: "TIF cases",
    path: "tif_cases",
    isSortable: true,
  },
  {
    by: "persent_of_passes",
    title: "% of passes",
    path: "persent_of_passes",
    isSortable: true,
    copyToClipBoardCallBack: (value) => (value ? (+value).toFixed(2) + "%" : "0%"),
    getCellValue: (value) => (value ? (+value).toFixed(2) + "%" : "0%"),
  },
  {
    by: "withdrawal_cases",
    title: "Withdrawal cases",
    path: "withdrawal_cases",
    isSortable: true,
  },
  {
    by: "withdrawal_to_sales",
    title: "Withdrawal % to sales",
    path: "withdrawal_to_sales",
    isSortable: true,
    copyToClipBoardCallBack: (value) => (value ? (+value).toFixed(2) + "%" : "0%"),
    getCellValue: (value) => (value ? (+value).toFixed(2) + "%" : "0%"),
  },
];
