import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import Select from "../Select";
import { DATE_FORMAT } from "../../../constants";

interface IProps {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (date: MaterialUiPickersDate, inputName: string) => void;
  onClear: () => void;
  label: string;
}

export const DATE_RANGE_OPTIONS = [
  {
    name: "after",
    text: "After",
  },
  {
    name: "before",
    text: "Before",
  },
  {
    name: "between",
    text: "Between",
  },
];

type RangeType = (typeof DATE_RANGE_OPTIONS)[number]["name"];

const DateRangePicker: React.FC<IProps> = ({ startDate, endDate, onChange, onClear, label }) => {
  const [selectedRange, setSelectedRange] = useState<RangeType>("after");

  const handleRangeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newRange = event.target.value as RangeType;
    setSelectedRange(newRange);
  };

  useEffect(() => {
    onClear();
  }, [selectedRange]);

  const isSelectAfter = ["after", "between"].includes(selectedRange);
  const isSelectBefore = ["before", "between"].includes(selectedRange);

  return (
    <>
      <Grid item xs={2}>
        <Select
          inputLabel={label}
          label={label}
          value={selectedRange}
          onChange={handleRangeChange}
          options={DATE_RANGE_OPTIONS}
        />
      </Grid>
      {isSelectAfter && (
        <Grid item xs={2}>
          <KeyboardDatePicker
            fullWidth
            autoOk
            inputVariant="outlined"
            format={DATE_FORMAT}
            label={label + " after"}
            name="after"
            value={startDate}
            onChange={(date) => onChange(date, "after")}
          />
        </Grid>
      )}
      {isSelectBefore && (
        <Grid item xs={2}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            inputVariant="outlined"
            format={DATE_FORMAT}
            label={label + " before"}
            name="before"
            value={endDate}
            onChange={(date) => onChange(date, "before")}
            minDate={selectedRange === "between" && startDate ? startDate : undefined}
          />
        </Grid>
      )}
    </>
  );
};

export default DateRangePicker;
