/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReduxAction } from "../static-types";
import { ISortBy } from "../types";

export type CustomersAction =
  | CustomersFetchRequest
  | CustomersFetchSuccess
  | CustomersFetchFailed
  // All customers
  | AllCustomersFetchRequest
  | AllCustomersFetchSuccess
  | AllCustomersFetchFailed;

interface CustomersFetchRequest extends ReduxAction {
  type: CustomersActionType.fetchRequest;
}
interface CustomersFetchSuccess extends ReduxAction {
  type: CustomersActionType.fetchSuccess;
  payload: {
    customers: CustomersData;
    sortParams: TSortParams;
  };
}
interface CustomersFetchFailed extends ReduxAction {
  type: CustomersActionType.fetchFailed;
}
// All customers
interface AllCustomersFetchRequest extends ReduxAction {
  type: CustomersActionType.fetchAllCustomersRequest;
}
interface AllCustomersFetchSuccess extends ReduxAction {
  type: CustomersActionType.fetchAllCustomersSuccess;
  payload: {
    customers: CustomerState[];
  };
}
interface AllCustomersFetchFailed extends ReduxAction {
  type: CustomersActionType.fetchAllCustomersFailed;
}

export enum CustomersActionType {
  fetchRequest = "customers/FetchRequest",
  fetchSuccess = "customers/FetchSuccess",
  fetchFailed = "customers/FetchFailed",
  // All customers
  fetchAllCustomersRequest = "customers/FetchAllCustomersRequest",
  fetchAllCustomersSuccess = "customers/FetchAllCustomersSuccess",
  fetchAllCustomersFailed = "customers/FetchAllCustomersFailed",
}

export type TBy =
  | "id"
  | "name"
  | "email"
  | "joined_after"
  | "joined_before"
  | "expires_after"
  | "expires_before"
  | "country"
  | "plan_name"
  | "note"
  | "flag"
  | "status"
  | "compare_total"
  | "primaryLanguage"
  | "last4"
  | "total";

export const CustomersActions = {
  fetchRequest: (): CustomersAction => ({
    type: CustomersActionType.fetchRequest,
  }),
  fetchSuccess: (customers: CustomersData, sortParams: TSortParams): CustomersAction => ({
    type: CustomersActionType.fetchSuccess,
    payload: {
      customers,
      sortParams,
    },
  }),
  fetchFailed: (): CustomersAction => ({
    type: CustomersActionType.fetchRequest,
  }),
  // All customers
  fetchAllCustomersRequest: (): CustomersAction => ({
    type: CustomersActionType.fetchAllCustomersRequest,
  }),
  fetchAllCustomersSuccess: (customers: CustomerState[]): CustomersAction => ({
    type: CustomersActionType.fetchAllCustomersSuccess,
    payload: {
      customers,
    },
  }),
  fetchAllCustomersFailed: (): CustomersAction => ({
    type: CustomersActionType.fetchAllCustomersFailed,
  }),
};

export interface CustomerState {
  id: number;
  phone?: string;
  account_plans: {
    id: number;
    expires: string;
    plan: {
      id: number;
      name: string;
    };
    plans_id: number;
    status: "active" | "canceled";
    passed: boolean | null;
  };
  countries_id: number;
  country: {
    id: number;
    name: string;
  };
  email: string;
  joined: string;
  name: string;
  billing_histories: {
    accounts_id: number;
    total: string;
  };
  account_notes: {
    note: string | null;
  };
  account_agreements: {
    status: string | null;
  };
  account_flags: {
    flag: string | null;
  };
  status?: number;
  index_row: number;
  stateprov: string;
  free_reset: number;
  last4: string;
  banned_accounts: null | { id: number };
  address: string | null;
}

export interface CustomersData {
  accounts: CustomerState[];
  allAccounts: CustomerState[];
  count: number;
}

export type TSortParams = ISortBy<TBy>;

export interface CustomersState {
  data: CustomersData;
  loading: boolean;
}

const initialState: CustomersState = {
  data: {
    accounts: [],
    allAccounts: [],
    count: 0,
  },
  loading: false,
};

const customersReducer = (prevState = initialState, action: CustomersAction): CustomersState =>
  produce(prevState, (draft: CustomersState): CustomersState => {
    switch (action.type) {
      case CustomersActionType.fetchRequest:
        draft.loading = true;
        break;
      case CustomersActionType.fetchSuccess:
        draft.data.count = action.payload.customers.count;
        draft.data.accounts = action.payload.customers.accounts;
        draft.loading = false;
        break;
      case CustomersActionType.fetchFailed:
        draft.loading = false;
        break;
      // All customers
      case CustomersActionType.fetchAllCustomersSuccess:
        draft.loading = false;
        draft.data.allAccounts = action.payload.customers;
        break;
      default:
        return draft;
    }
    return draft;
  });
export default customersReducer;
