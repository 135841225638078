import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { useQuery } from "@tanstack/react-query";

import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import { buildColumns, IReport, buildReport } from "../../utils";
import Table from "../../../../ComplianceSearch/components/Table";
import useStyles from "../useStyles";
import { BILLING_API_URLS } from "../../../../../api/billing";
import { FinancialsActions } from "../../../../../reducers/financialsReducer/actions";
import { BillingAPI } from "../../../../../api/billing";
import FiltersByDateAndTypes, { useSearchValues } from "../../FiltersByDateAndTypes";

const PerSales: React.FC = () => {
  const classes = useStyles();
  const { data, loading } = useSelector((state) => state.financials.withdrawal_per_sales);
  const { billingHistory, w80 } = data;
  const { values, handlers } = useSearchValues({ initialTimeFrame: "week" });

  const dispatch = useDispatch();

  const [columns, setColumns] = useState<IColum[]>([]);
  const [report, setReport] = useState<IReport[]>([]);

  useQuery({
    enabled: !!values.startDate || !!values.endDate,
    queryKey: [
      BILLING_API_URLS.withdrawalRates,
      "withdrawal_per_sales",
      values.startDate,
      values.endDate,
      values.isAllTime,
      values.timeFrame,
      values.typeAccount,
    ],
    async queryFn({ signal }) {
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerSalesRequest());
      const query = new URLSearchParams();
      query.append("startDate", values.startDate);
      query.append("endDate", values.endDate);
      query.append("isAllTime", String(values.isAllTime));
      query.append("timeFrame", values.timeFrame);
      query.append("account_type", values.typeAccount);

      const response = await BillingAPI.getWithdrawalRatesRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerSalesSuccess(response.data));
      return response;
    },
    onError() {
      console.error("Error fetching withdrawal per sales");
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerSalesFailed());
    },
  });

  useEffect(() => {
    const dates = Array.from(new Set(data.w80.map(({ period }) => period)));

    const columns = buildColumns({
      dates,
      reportType: "amount",
      isUseChangeToPrev: false,
      initialColumns: [
        {
          by: "plan",
          title: "Plan",
          path: "plan",
        },
      ],
    });
    setColumns(columns);

    const reportWithWithdawalPerSales = w80.map((report) => {
      const selectedPeriodIndex = dates.findIndex((date) => date === report.period);
      const slippagePeriod = selectedPeriodIndex + values.slippagePeriod;

      const billingHistoryByPlan = billingHistory.find(({ period, plan }) => {
        if (values.slippagePeriod) {
          return period === dates[slippagePeriod] && report.account_type === plan?.key;
        }

        return period === report.period && report.account_type === plan?.key;
      });

      return {
        ...report,
        account_size: "",
        total_amount: billingHistoryByPlan
          ? report.total_amount / billingHistoryByPlan.total_count
          : 0,
      };
    });

    const report = buildReport({
      data: reportWithWithdawalPerSales,
      dates,
      options: { isShowTotal: false },
    });

    setReport(report);
  }, [data, values.slippagePeriod]);

  return (
    <div>
      <h3 className={classes.tableNameTitle}>Withdrawal per Sales</h3>

      <div className={cx(classes.container, classes.tableNameTitle)}>
        <FiltersByDateAndTypes
          timeFrame={values.timeFrame}
          typeAccount={values.typeAccount}
          slippagePeriod={values.slippagePeriod}
          onTimeFrameChange={handlers.handleChangeTimeFrame}
          onTypeAccountChange={handlers.handleChangeTypeAccount}
          onDateChange={handlers.handleDateChange}
          handleChangeSlippagePeriod={handlers.handleChangeSlippagePeriod}
        />
      </div>

      <Table
        csv_table_name="Withdrawal per Sales"
        columns={columns}
        loading={loading}
        data={report}
        extractKey={(row) => row.plan}
      />
    </div>
  );
};

export default PerSales;
