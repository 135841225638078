import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FileError } from "react-dropzone";

import useStyles from "./useStyles";
import DropZone from "../../../../components/ui/DropZone";
import { useAdminProviderDataContext } from "../../../../context";
import { RITHMIC_API_URLS, RithmicAPI } from "../../../../api/rithmic";

const FILE_PATTERN = /^paper_Helios_(\d{8})_quoted\.zip$/; // Expected pattern
const validateFileName = (fileName: File): FileError | FileError[] | null => {
  const match = FILE_PATTERN.exec(fileName.name);
  if (!match) {
    return {
      message: "Invalid file name. Expected format: paper_Helios_YYYYMMDD_quoted.zip",
      code: "file-invalid-name",
    };
  }

  const datePart = match[1]; // Extract YYYYMMDD
  const year = parseInt(datePart.substring(0, 4), 10);
  const month = parseInt(datePart.substring(4, 6), 10) - 1; // JS months are 0-based
  const day = parseInt(datePart.substring(6, 8), 10);

  const date = new Date(year, month, day);

  return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day
    ? null
    : {
        message: "Invalid file name. Expected format: paper_Helios_YYYYMMDD_quoted.zip",
        code: "file-invalid-date",
      };
};

const UploadHistoryData = (): React.ReactElement => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const handleUploadFiles = useMutation({
    mutationKey: [RITHMIC_API_URLS.rithmic_historical_data],

    mutationFn: async ({ files, clearFiles }: { files: File[]; clearFiles: () => void }) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });

      await RithmicAPI.uploadRithmicHistoricalDataFilesRequest(files);
      clearFiles();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RITHMIC_API_URLS.get_rithmic_available_historical_data],
      });
    },
    onError: (error: { response: { data: { message: string } } }) => {
      setError(error.response.data.message);
    },
  });

  return (
    <div className={classes.dropZoneContainer}>
      <DropZone
        disabled={!allowed_features.access_upload_rithmic_historical_orders}
        isLoading={handleUploadFiles.isLoading}
        dropZoneOptions={{
          accept: {
            "application/zip": [".zip"],
          },
          maxFiles: 5,
          multiple: true,
          validator: validateFileName,
        }}
        handleUploadFiles={(files, handleClearFiles) =>
          handleUploadFiles.mutate({ files, clearFiles: handleClearFiles })
        }
        allowedFilesDescription="Only paper_Helios_YYYYMMDD_quoted.zip files will be accepted"
      />
      {error && <p className={classes.error}>{error}</p>}
    </div>
  );
};

export default UploadHistoryData;
