import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import cx from "classnames";

import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import { buildColumns, IReport, buildReport } from "../../utils";
import Table from "../../../../ComplianceSearch/components/Table";
import useStyles from "../useStyles";
import { BillingAPI } from "../../../../../api/billing";

import { BILLING_API_URLS } from "../../../../../api/billing";
import { FinancialsActions } from "../../../../../reducers/financialsReducer/actions";
import FiltersByDateAndTypes, { useSearchValues } from "../../FiltersByDateAndTypes";

const AveragePerProduct: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { values, handlers } = useSearchValues();
  const { data, loading } = useSelector((state) => state.financials.withdrawal_per_requests);
  const [columns, setColumns] = useState<IColum[]>([]);
  const [report, setReport] = useState<IReport[]>([]);

  useQuery({
    enabled: !!values.startDate || !!values.endDate,
    queryKey: [
      BILLING_API_URLS.withdrawalRates,
      "withdrawal_per_requests",
      values.startDate,
      values.endDate,
      values.isAllTime,
      values.timeFrame,
      values.typeAccount,
    ],
    async queryFn({ signal }) {
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerRequestsRequest());
      const query = new URLSearchParams();
      query.append("startDate", values.startDate);
      query.append("endDate", values.endDate);
      query.append("isAllTime", String(values.isAllTime));
      query.append("timeFrame", values.timeFrame);
      query.append("account_type", values.typeAccount);

      const response = await BillingAPI.getWithdrawalRatesRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerRequestsSuccess(response.data));
      return response;
    },
    onError() {
      console.error("Error fetching withdrawal rates");
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerRequestsFailed());
    },
  });

  useEffect(() => {
    const dates = Array.from(new Set(data.w80.map(({ period }) => period)));
    setColumns(buildColumns({ dates, reportType: "amount", isUseChangeToPrev: false }));

    const reportWithWithdawalPerRequest = data.w80.map((report) => ({
      ...report,
      account_size: "",
      total_amount: report.total_amount / report.total_count,
    }));

    setReport(
      buildReport({ data: reportWithWithdawalPerRequest, dates, options: { isShowTotal: false } })
    );
  }, [data]);

  return (
    <div>
      <h3 className={classes.tableNameTitle}>Average per product</h3>

      <div className={cx(classes.container, classes.tableNameTitle)}>
        <FiltersByDateAndTypes
          timeFrame={values.timeFrame}
          typeAccount={values.typeAccount}
          onTimeFrameChange={handlers.handleChangeTimeFrame}
          onTypeAccountChange={handlers.handleChangeTypeAccount}
          onDateChange={handlers.handleDateChange}
        />
      </div>

      <Table
        csv_table_name="Average per product"
        columns={columns}
        loading={loading}
        data={report}
        extractKey={(row) => row.plan}
      />
    </div>
  );
};

export default AveragePerProduct;
