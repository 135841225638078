import React from "react";
import * as LogRocket from "logrocket";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { Plan } from "../../Customer";
import { APIIntercom } from "../../../../api";
import { useCustomerProvider } from "../../../../context";
import { ACCOUNTS_API_URLS } from "../../../../api/accounts";
import { CENTS_IN_DOLLAR } from "../../../../constants";
import { getPlanPrice } from "../../../../components/ui/DiscountsModal/utils";
import { getNewPrice } from "../../../../components/ui/DiscountsModal/utils";
import { IPlan } from "../../../../api/plans/index.types";
import { IDiscountCodeForPlan } from "../../../../api/discounts/index.types";

interface IProps {
  editingPlan: Plan;
  editingPlanSaveStatus: string;
  setEditingPlan: (plan?: Plan) => void;
  setEditingPlanSaveStatus: (s: string) => void;
  editingPlanDiscountCode: IDiscountCodeForPlan | null;
}

const getPlanPriceWithDiscount = (
  plans: IPlan[],
  editingPlanDiscountCode: IDiscountCodeForPlan,
  editingPlan: Plan
) => {
  console.log("editingPlanDiscountCode.plan_id", editingPlanDiscountCode.plan_id);
  console.log("editingPlan.plan_id", editingPlan);
  const newPrice = getNewPrice({
    plans,
    code: {
      // Handle the case when the discount code is assigned to All plans
      plan: editingPlanDiscountCode.plan_id
        ? Number(editingPlanDiscountCode.plan_id)
        : editingPlan.plans_id,
      amount: +editingPlanDiscountCode.amt,
      percent: editingPlanDiscountCode.type === "percent" ? true : false,
    },
  });

  const planPrice = getPlanPrice(plans, Number(editingPlanDiscountCode.plan_id)) / 100;
  const planPriceWithDiscount = planPrice - newPrice;

  return +(
    editingPlanDiscountCode.type === "percent" ? newPrice * 100 : planPriceWithDiscount * 100
  ).toFixed(2);
};

const UpdatePlanConfirmWindow: React.FC<IProps> = ({
  editingPlan,
  editingPlanSaveStatus,
  setEditingPlan,
  setEditingPlanSaveStatus,
  editingPlanDiscountCode,
}) => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const queryClient = useQueryClient();
  const { plans } = useSelector((state) => state.plans.data);

  const { customer } = useCustomerProvider();
  const { data } = useSelector((state) => state.customer.plans);

  const current_edit_plan = data.plans.find(({ id }) => editingPlan.id === id);

  const isAddedDiscountCode = editingPlan.discount_code_id !== current_edit_plan?.discount_code_id;

  const saveEditingPlan = async () => {
    setEditingPlanSaveStatus("Please wait...");

    try {
      await APIIntercom.saveEditingPlanRequest(customer.xid, {
        id: editingPlan.id,
        expires: editingPlan.expires,
        previous_expires: current_edit_plan ? current_edit_plan.expires : editingPlan.expires,
        price_usd: editingPlan.price_usd * CENTS_IN_DOLLAR,
        previous_price_usd: current_edit_plan
          ? current_edit_plan.price_usd * CENTS_IN_DOLLAR
          : editingPlan.price_usd * CENTS_IN_DOLLAR,
        status: editingPlan.status,
        accounts_id: customer.id,
        ...(isAddedDiscountCode &&
          editingPlanDiscountCode && {
            discount_code_id: editingPlanDiscountCode.id,
            price_usd: getPlanPriceWithDiscount(plans, editingPlanDiscountCode, editingPlan),
          }),
      });

      setEditingPlanSaveStatus("Success!");
      LogRocket.track("Customer: Change Rebill Data");

      queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.accountPlans, id, 0],
      });
      queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.accountPlans],
      });
      setTimeout(() => {
        setEditingPlan();
      }, 2000);
    } catch (error) {
      setEditingPlanSaveStatus("Error!");
      console.error(error);
      setTimeout(() => {
        setEditingPlan();
      }, 2000);
    }
  };

  return (
    <div className="popup popup-save">
      <div className="title">Are you sure?</div>
      Are you sure you want to update this plan to the following?
      <br />
      <br />
      <b>Active: </b>
      {editingPlan.status === "active" ? "Yes" : "No"}
      <br />
      <b>Rebill Date: </b>
      {editingPlan.expires}
      <br />
      <b>Price: </b>$
      {isAddedDiscountCode && editingPlanDiscountCode
        ? getPlanPriceWithDiscount(plans, editingPlanDiscountCode, editingPlan) / CENTS_IN_DOLLAR
        : editingPlan.price_usd}
      <div style={{ padding: "10px 0" }}>{editingPlanSaveStatus}</div>
      <button type="button" onClick={() => setEditingPlan()}>
        Cancel
      </button>
      <button
        type="button"
        onClick={() => saveEditingPlan()}
        className="yes"
        data-test="button-save-intercom-profile"
      >
        Update
      </button>
    </div>
  );
};

export default UpdatePlanConfirmWindow;
