import React from "react";
import { makeStyles } from "@material-ui/core";

import { CUSTOMERS_FILTERS, INPUT_TYPES } from "../../constants";

import { PRIMARY_LANGUAGE_OPTIONS } from "../../../FundedTraders/utils";
import Select from "../../../../components/ui/Select";
import FilterAutocomplete from "../../../../components/ui/FilterAutocomplete";
import { useSearchCustomersContext } from "../../../../context";

const useStyles = makeStyles({
  filtersWrapper: {
    display: "flex",
    marginTop: 15,
    marginBottom: 5,
  },
  emailSearch: {
    width: 300,
  },
  regionContainer: {
    margin: "0 5px 10px 5px",
    width: 320,
  },
});

interface IProps {
  keyVal: number;
}
const CustomersFilters: React.FC<IProps> = ({ keyVal }) => {
  const classes = useStyles();
  const { search, setSearch } = useSearchCustomersContext();
  const handleChangeRegion = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setSearch({ ...search, primaryLanguage: event.target.value as string });
  };

  return (
    <div className={classes.filtersWrapper}>
      <FilterAutocomplete
        clearValuesFilter={keyVal}
        search={search}
        setSearch={setSearch}
        filters={CUSTOMERS_FILTERS}
        inputTypes={INPUT_TYPES}
        defaultSearchFields={{
          Name: "name",
          Email: "email",
        }}
      />
      <div className={classes.regionContainer}>
        <Select
          inputLabel="Primary language"
          label="Primary language"
          value={search.primaryLanguage}
          onChange={handleChangeRegion}
          options={PRIMARY_LANGUAGE_OPTIONS}
        />
      </div>
    </div>
  );
};

export default CustomersFilters;
