import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
} from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";

import useStyles from "./useStyles";
import AccountsList from "./components/AccountsList";
import { APIFunds } from "../../../../api";
import { ITradedAccount } from "../../../../api/funds/index.types";
import { getAccountsToShow } from "./utils";
import { EVALUATIONS_API_URLS } from "../../../../api/evaluations";
import { checkIsCompletedAccount } from "../../utils";
import { EEvaluationType, EProcessingStatuses } from "../../../../constants";

interface IProps {
  title: string;
  listItems: ITradedAccount[];
  priorAccounts: ITradedAccount[];
  fetchTradedAccounts: () => Promise<void>;
  withCollapse?: boolean;
}
interface IConfirmValue {
  id: number;
  accounts_id: number;
  processing_status?: string;
  account_type?: EEvaluationType;
  invoice_status?: string;
}
const TradedAccountsList: React.FC<IProps> = ({
  title,
  listItems,
  fetchTradedAccounts,
  withCollapse,
  priorAccounts,
}) => {
  const queryClient = useQueryClient();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [isCollapsedPriorAccounts, setIsCollapsedPriorAccounts] = useState(false);
  const [isOpenDialogConfirm, setIsOpenDialogConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    dialogTitle: "",
    dialogContent: "",
  });
  const [confirmValue, setConfirmValue] = useState<IConfirmValue>({
    id: -1,
    accounts_id: -1,
  });

  const handleSaveChangeProcessingStatus = async () => {
    try {
      setIsLoading(true);
      await APIFunds.updateAccountProcessingStatus(confirmValue.accounts_id, confirmValue);
      await fetchTradedAccounts();
    } catch (error) {
      console.error("Error update processing status", error);
    } finally {
      setIsLoading(false);
      setIsOpenDialogConfirm(false);
      await queryClient.invalidateQueries({
        queryKey: [EVALUATIONS_API_URLS.customerEvaluationCompleteHistory],
      });
    }
  };

  const handleChangeProcessingStatus = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    id: number,
    accounts_id: number
  ) => {
    setIsLoading(true);
    if (checkIsCompletedAccount(e.target.value as EProcessingStatuses)) {
      const newProcessingStatus = (e.target.value as EProcessingStatuses).toUpperCase();
      setDialogContent({
        dialogTitle: `Change status to ${newProcessingStatus}`,
        dialogContent: `Are you sure you want to change the processing status to 
          ${newProcessingStatus}? This operation is non-reversible.`,
      });
    } else {
      setDialogContent({
        dialogTitle: "Change status of account",
        dialogContent: "Are you sure you want to change the processing status of the account?",
      });
    }
    if (e.target.value) {
      setConfirmValue({
        processing_status: e.target.value as EProcessingStatuses,
        id,
        accounts_id,
      });
      setIsOpenDialogConfirm(true);
    }
    setIsLoading(false);
  };

  const handleChangeInvoiceStatus = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    id: number,
    accounts_id: number
  ) => {
    setDialogContent({
      dialogTitle: "Change Invoice status of account",
      dialogContent: "Are you sure you want to change the invoice status of the account?",
    });

    if (e.target.value) {
      setConfirmValue({
        invoice_status: e.target.value as string,
        id,
        accounts_id,
      });
      setIsOpenDialogConfirm(true);
    }
  };

  const accountsToShow: ITradedAccount[] = getAccountsToShow(listItems);
  const accountsCount = accountsToShow.length;

  return (
    <div>
      <Dialog
        open={isOpenDialogConfirm}
        onClose={() => setIsOpenDialogConfirm(!isOpenDialogConfirm)}
      >
        <DialogTitle>{dialogContent.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent.dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpenDialogConfirm(false);
            }}
            size="small"
            color="primary"
            variant="contained"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveChangeProcessingStatus}
            size="small"
            color="secondary"
            autoFocus
            variant="contained"
            disabled={isLoading}
            data-test="button-submit"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <b>{title}</b>
      {withCollapse && accountsCount ? (
        <div>
          <Collapse in={isCollapsedPriorAccounts} timeout="auto" unmountOnExit>
            <AccountsList
              listItems={listItems}
              accountsToShow={accountsToShow}
              priorAccounts={priorAccounts}
              handleChangeProcessingStatus={handleChangeProcessingStatus}
              handleChangeInvoiceStatus={handleChangeInvoiceStatus}
            />
          </Collapse>
          <Button
            className={classes.showPriorAccountsButton}
            variant="contained"
            color="primary"
            onClick={() => setIsCollapsedPriorAccounts(!isCollapsedPriorAccounts)}
          >
            {isCollapsedPriorAccounts ? "Hide" : "Show"} `{accountsCount}` prior accounts
          </Button>
        </div>
      ) : (
        !!accountsCount && (
          <AccountsList
            listItems={listItems}
            accountsToShow={accountsToShow}
            priorAccounts={priorAccounts}
            handleChangeProcessingStatus={handleChangeProcessingStatus}
            handleChangeInvoiceStatus={handleChangeInvoiceStatus}
          />
        )
      )}
    </div>
  );
};

export default TradedAccountsList;
