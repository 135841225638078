import React, { useState } from "react";
import { FormikProps } from "formik";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import SearchAutocomplete from "../../../../components/ui/SearchAutocomplete";
import { IDiscountCodeForPlan } from "../../../../api/discounts/index.types";
import { ICustomerPlan } from "../../../../api/accounts/index.types";
import { DiscountsAPI, DISCOUNT_API_URLS } from "../../../../api/discounts";

interface IProps {
  setEditingPlanDiscountCode: (p: IDiscountCodeForPlan | null) => void;
  form: FormikProps<ICustomerPlan>;
  plan: ICustomerPlan;
  index: number;
}

const AssignDiscountCodeToPlan: React.FC<IProps> = ({
  setEditingPlanDiscountCode,
  form,
  index,
  plan,
}) => {
  const { id }: { id: string } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState({
    code: "",
    plans_id: 0,
  });
  const [discountCodes, setDiscountCodes] = useState<IDiscountCodeForPlan[]>([]);

  useQuery({
    enabled: !!search.code,
    queryKey: [DISCOUNT_API_URLS.search_discount_codes_for_plan, search, id],
    queryFn: async ({ signal }) => {
      setIsLoading(true);
      const query = new URLSearchParams();
      query.append("discount_code", search.code);
      query.append("plan_id", search.plans_id.toString());
      query.append("accounts_id", id);

      const response = await DiscountsAPI.searchDiscountCodesForPlanRequest(
        query.toString(),
        signal
      );
      setDiscountCodes(response.data.codes);
      setIsLoading(false);
      return response;
    },
  });

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch({
      ...search,
      code: e.target.value,
      plans_id: plan.plans_id,
    });

  const onChange = (e: IDiscountCodeForPlan) => {
    const isNotSelectedDiscountCode = !Object.keys(e).length;
    if (isNotSelectedDiscountCode) {
      setEditingPlanDiscountCode(null);
      form.setFieldValue(`plans.${index}.discount_code_id`, null);
      return;
    }

    form.setFieldValue(`plans.${index}.discount_code_id`, e.id);
    setEditingPlanDiscountCode(e);
  };

  return (
    <SearchAutocomplete
      label="Discount code"
      onChange={onChange}
      options={discountCodes}
      getOptionLabel={(option) => option.code}
      getOptionSelected={(option, value) => option.id === value.id}
      handleChangeInput={handleChangeInput}
      loading={isLoading}
      open={isOpen}
      setOpen={setIsOpen}
    />
  );
};

export default AssignDiscountCodeToPlan;
