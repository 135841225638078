import { IntlFormatUSD } from "../../../utils";
import { IColum } from "../../../utils/copyToClipboardInXlsFormat";
import { PLANS_NAMES_MAP } from "../constants";

export interface IReport {
  plan: string;
  values: number[];
}

export const getInterestBetweenTwoValues = (value: number[], index: number): string => {
  const prevValue = value[index + 1];
  const currentValue = value[index];
  const change = ((currentValue - prevValue) / prevValue) * 100;
  if (prevValue === 0) return "";
  if (isNaN(change)) return "";

  return `${change.toFixed(0)}%`;
};

type IBuildColumnsByDates = (args: {
  dates: string[];
  reportType?: "cases" | "amount";
  isUseChangeToPrev?: boolean;
  initialColumns?: IColum[];
}) => IColum[];

export const buildColumns: IBuildColumnsByDates = ({
  dates,
  reportType,
  isUseChangeToPrev = true,
  initialColumns = [
    {
      by: "plan",
      title: "Plan",
      path: "plan",
    },
  ],
}) => {
  const columnsByDate: IColum[] = [...initialColumns];

  dates.forEach((date, index) => {
    columnsByDate.push({
      by: date,
      title: date,
      path: "values",
      //@ts-ignore
      getCellValue: (value: number[]) => value[index],
      //@ts-ignore
      copyToClipBoardCallBack: (value: number[]) => value[index],
      ...(reportType && {
        getCellValue: (value: number[]) =>
          reportType === "amount" ? IntlFormatUSD(value[index]) : value[index],
        copyToClipBoardCallBack: (value: number[]) =>
          reportType === "amount" ? IntlFormatUSD(value[index]) : value[index],
      }),
    });

    if (index !== dates.length - 1 && isUseChangeToPrev) {
      columnsByDate.push({
        by: index.toString(),
        title: "Change to prev",
        path: "values",
        //@ts-ignore
        getCellValue: (value: number[]) => getInterestBetweenTwoValues(value, index),
        //@ts-ignore
        copyToClipBoardCallBack: (value: number[]) => getInterestBetweenTwoValues(value, index),
      });
    }
  });

  return columnsByDate;
};

type IBuildReport = (args: {
  data: {
    period: string;
    total_amount: number;
    account_size?: string;
    account_type?: string;
  }[];
  dates: string[];
  options?: {
    isShowTotal?: boolean;
  };
}) => IReport[];

const getPlan = (account_type?: string, account_size?: string): string => {
  const planName = PLANS_NAMES_MAP[account_type as keyof typeof PLANS_NAMES_MAP];
  if (planName) {
    return planName;
  }

  return account_size || "";
  // return
  // `${account_type || ""} ${account_size && account_type ? " - " : ""} ${account_size || ""}`;
  // return account_type || account_size
  //   ? `${account_type || ""} ${account_size && account_type ? " - " : ""} ${account_size || ""}`
  //   : "";
};

export const buildReport: IBuildReport = ({
  data,
  dates,
  options = {
    isShowTotal: true,
  },
}) => {
  const reportDataByPlan: Record<string, { values: number[] }> = {};
  const report: IReport[] = [];

  data.forEach((stats) => {
    const { account_size, account_type } = stats;
    const plan = getPlan(account_type, account_size);

    if (!reportDataByPlan[plan]) {
      reportDataByPlan[plan] = { values: Array(dates.length).fill(0) };
    }
  });

  data.forEach((stats) => {
    const { period, total_amount, account_size, account_type } = stats;

    const index = dates.indexOf(period);
    const plan = getPlan(account_type, account_size);

    reportDataByPlan[plan].values[index] += total_amount;
  });

  Object.values(PLANS_NAMES_MAP).forEach((plan) => {
    if (reportDataByPlan[plan]) {
      report.push({
        plan,
        values: reportDataByPlan[plan].values,
      });
    }
  });

  if (options.isShowTotal) {
    const totalAmountByDate = Object.values(reportDataByPlan).reduce((acc, row) => {
      return acc.map((sum, i) => sum + row.values[i]);
    }, Array(dates.length).fill(0));

    report.push({
      plan: "Total",
      values: totalAmountByDate,
    });
  }

  return report;
};
