/* eslint-disable no-param-reassign */
import produce from "immer";
import { ITradedAccount } from "../../api/funds/index.types";
import { checkIsCompletedAccount } from "../../pages/FundedTrader/utils";
import { FundedTraderActionType, FundedTraderAction, IFundedTraderState } from "./index.types";

const initialState: IFundedTraderState = {
  tradedAccounts: {
    data: [],
    isLoading: true,
  },
  documentsDates: {
    isLoading: false,
    data: {
      address_proof: null,
      w8or9: null,
      personal_id: null,
    },
  },
  isLoading: false,
  fundedAccounts: [],
  priorAccounts: [],
  accounts_id: -1,
  id: 0,
  timestamp: "",
  account: {
    name: "",
    email: "",
  },
  notes: {
    data: [],
    count: 0,
    isLoading: false,
  },
  is_business_ein: false,
  business_ein_name: null,
  start_trading_date: null,
  is_professional_live_sim: false,
  is_priority_to_process: false,
  e2t_interview: null,
  on_vacation_until: null,
  e2t_affiliate_value: null,
  net_withdrawal_amount: 0,
  is_forced_to_live: false,
  pep: "no",
  watermark: 0,
  livesim_net_withdrawal_amount: 0,
  passes_before_envoy: 0,
  withdrawals_before_envoy: 0,
  total_withdrawn_before_envoy: 0,
  funded_trader_status: {
    id: 1,
    status_name: "Recent Passer",
    status: "recent_passer",
  },
  ban_date: null,
  evaluation_type: null,
  invoice_status: null,
  processing_status: null,
  evaluationCompletedHistory: {
    data: [],
    count: 0,
    isLoading: false,
  },
  evaluationCompletedActiveOrRecentAccounts: {
    data: [],
    count: 0,
    isLoading: false,
  },
};

const fundedTraderReducer = (
  prevState = initialState,
  action: FundedTraderAction
): IFundedTraderState =>
  produce(prevState, (draft: IFundedTraderState) => {
    switch (action.type) {
      case FundedTraderActionType.fundedTraderAccountsFetchRequest:
        draft.tradedAccounts.isLoading = true;
        break;
      case FundedTraderActionType.fundedTraderAccountsFetchSuccess: {
        draft.tradedAccounts.isLoading = false;
        draft.tradedAccounts.data = action.payload;
        const fundedAccounts: ITradedAccount[] = [];
        const priorAccounts: ITradedAccount[] = [];
        action.payload.forEach((account) => {
          if (
            checkIsCompletedAccount(
              account.processing_status,
              account.original_account,
              account,
              action.payload
            )
          ) {
            priorAccounts.push(account);
          } else {
            fundedAccounts.push(account);
          }
        });
        draft.priorAccounts = priorAccounts;
        draft.fundedAccounts = fundedAccounts;
        break;
      }
      case FundedTraderActionType.fundedTraderAccountsFetchFailed:
        draft.tradedAccounts.isLoading = false;
        break;
      case FundedTraderActionType.fundedTraderDataFetchRequest:
        draft.isLoading = true;
        break;
      case FundedTraderActionType.fundedTraderDataFetchSuccess:
        draft = {
          ...draft,
          ...action.payload.funded_trader,
          net_withdrawal_amount: action.payload.forced_to_live?.rows[0]?.net_withdrawal_amount || 0,
        };
        draft.isLoading = false;
        break;
      case FundedTraderActionType.fundedTraderDataFetchFailed:
        draft.isLoading = false;
        break;
      case FundedTraderActionType.fundedTraderDocumentsDatesFetchRequest:
        draft.documentsDates.isLoading = true;
        break;
      case FundedTraderActionType.fundedTraderDocumentsDatesFetchSuccess:
        draft.documentsDates.data = action.payload;
        draft.documentsDates.isLoading = false;
        break;
      case FundedTraderActionType.fundedTraderDocumentsDatesFetchFailed:
        draft.documentsDates.isLoading = false;
        break;
      case FundedTraderActionType.fundedTraderNotesFetchRequest:
        draft.notes.isLoading = true;
        break;
      case FundedTraderActionType.fundedTraderNotesFetchSuccess:
        draft.notes.data = action.payload.data.notes;
        draft.notes.count = action.payload.data.count;
        draft.notes.isLoading = false;
        break;
      case FundedTraderActionType.fundedTraderNotesFetchFailed:
        draft.notes.isLoading = false;
        break;
      case FundedTraderActionType.fundedTraderEvaluationCompletedHistoryFetchRequest:
        draft.evaluationCompletedHistory.isLoading = true;
        break;
      case FundedTraderActionType.fundedTraderEvaluationCompletedHistoryFetchSuccess:
        draft.evaluationCompletedHistory.data = action.payload.rows;
        draft.evaluationCompletedHistory.count = action.payload.count;
        draft.evaluationCompletedHistory.isLoading = false;
        break;
      case FundedTraderActionType.fundedTraderEvaluationCompletedHistoryFetchFailed:
        draft.evaluationCompletedHistory.isLoading = false;
        break;
      case FundedTraderActionType.fundedTraderPassesActiveAndRecentAccountsFetchRequest:
        draft.evaluationCompletedActiveOrRecentAccounts.isLoading = true;
        break;
      case FundedTraderActionType.fundedTraderPassesActiveAndRecentAccountsFetchSuccess:
        draft.evaluationCompletedActiveOrRecentAccounts.data = action.payload.rows;
        draft.evaluationCompletedActiveOrRecentAccounts.count = action.payload.count;
        draft.evaluationCompletedActiveOrRecentAccounts.isLoading = false;
        break;
      case FundedTraderActionType.fundedTraderPassesActiveAndRecentAccountsFetchFailed:
        draft.evaluationCompletedActiveOrRecentAccounts.isLoading = false;
        break;
      default:
        return draft;
    }
    return draft;
  });

export default fundedTraderReducer;
