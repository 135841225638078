import React, { useState, useCallback } from "react";
import cx from "classnames";
import DeleteIcon from "@material-ui/icons/Delete";
import { filesize } from "filesize";
import { IconButton, Button, CircularProgress as MUIProgress } from "@material-ui/core";
import { useDropzone, DropzoneOptions } from "react-dropzone";

import useStyles from "./useStyles";

interface IDropZoneProps {
  isLoading: boolean;
  dropZoneOptions: DropzoneOptions;
  handleUploadFiles: (files: File[], clearFiles: () => void) => void;
  allowedFilesDescription: string;
  disabled: boolean;
}

const DropZone: React.FC<IDropZoneProps> = ({
  isLoading,
  dropZoneOptions,
  handleUploadFiles,
  allowedFilesDescription,
  disabled,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const classes = useStyles();

  const isDisabled =
    (dropZoneOptions.maxFiles && files.length >= dropZoneOptions.maxFiles) || isLoading || disabled;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const uniqueFiles = acceptedFiles.filter(
        (file) => !files.some((existingFile) => existingFile.name === file.name)
      );
      setFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
    },
    [files]
  );

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    disabled,
    ...dropZoneOptions,
  });

  const acceptedFileItems = files.map((file: File, index: number) => (
    <li key={`${file.name}_${index}`} className={classes.acceptedItem}>
      {file.name} Size: {filesize(file.size)}{" "}
      <IconButton
        className={classes.deleteAddedFileIcon}
        disabled={isLoading}
        onClick={() => handleRemoveFile(index)}
      >
        <DeleteIcon className={classes.deletingIcon} fontSize="small" />
      </IconButton>
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {filesize(file.size)}
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleClearFiles = () => {
    setFiles([]);
  };

  return (
    <section className="container">
      <div
        {...getRootProps({ className: cx(classes.container, { [classes.disabled]: isDisabled }) })}
      >
        <input {...getInputProps()} />
        <p>Drag and drop some files here, or click to select files</p>
        <em>{allowedFilesDescription}</em>
        {dropZoneOptions.maxFiles && (
          <em>
            Maximum allowed amount of uploaded files are {dropZoneOptions.maxFiles}, uploaded{" "}
            {files.length} / {dropZoneOptions.maxFiles}
          </em>
        )}
      </div>

      {!!acceptedFileItems.length && (
        <aside>
          <h4>Accepted files</h4>
          <ul>{acceptedFileItems}</ul>
        </aside>
      )}

      {!!fileRejectionItems.length && (
        <aside>
          <h4>Rejected files</h4>
          <ul>{fileRejectionItems}</ul>
        </aside>
      )}

      {!!files.length && (
        <Button
          disabled={isLoading}
          variant="contained"
          color="primary"
          onClick={() => handleUploadFiles(files, handleClearFiles)}
          endIcon={isLoading && <MUIProgress color="inherit" size={20} />}
        >
          Upload
        </Button>
      )}
    </section>
  );
};

export default DropZone;
