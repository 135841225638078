import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useClearCache } from "react-clear-cache";

import PaymentIcon from "@material-ui/icons/Payment";
import TimelineIcon from "@material-ui/icons/Timeline";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/Search";
import ListIcon from "@material-ui/icons/List";
import HistoryIcon from "@material-ui/icons/History";

import * as SVG from "../../svgs";
import { useAuthContext } from "../../../context";
import { useAdminProviderDataContext } from "../../../context";
import useStyles from "./useStyles";

const SideNav = (): React.ReactElement | null => {
  interface IStyledNavLink {
    children: React.ReactNode;
    to: string;
    className?: string;
    onClick?: () => void;
    exact?: boolean;
  }

  const { token, login, isExpiredCloudFlareToken } = useAuthContext();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const { isLatestVersion, emptyCacheStorage } = useClearCache({
    duration: 60 * 1000 * 30,
    auto: false,
    storageKey: "APP_VERSION",
    basePath: "",
  });
  const classes = useStyles();

  const StyledNavLink = useCallback(
    ({ children, to, className, onClick, exact }: IStyledNavLink) => (
      <NavLink className={className} to={to} onClick={() => onClick && onClick()} exact={exact}>
        {children}
      </NavLink>
    ),
    []
  );

  const handleUpdateVersion = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      await emptyCacheStorage();
      window.location.reload();
    } catch (error) {
      console.error("Error updating version", error);
    }
  };

  if (!token) {
    return null;
  }

  const {
    access_all_features,
    // access_withdrawals,
    // access_funded_traders,
    access_activity_feed_page,
    read_agents_log,
    access_customers_page,
    access_rithmic_accounts,
    access_to_discounts_tab,
    access_to_watchlist_tab,
    access_to_compliance_search_page,
  } = allowed_features;
  // const isHeliosAgent = access_withdrawals || access_funded_traders;

  return (
    <div className="sidebar">
      {!isLatestVersion && (
        <a className="bar-warning" href="./#" onClick={handleUpdateVersion}>
          Update version
        </a>
      )}

      {isExpiredCloudFlareToken && (
        <span className="bar-warning">
          Cloudflare authentication times out <b>Reload the page</b>
        </span>
      )}

      <h1 className={classes.title}>Envoy</h1>

      {(access_activity_feed_page || read_agents_log) && (
        <StyledNavLink to="/" exact data-test="activity-feed">
          <SVG.SVGDashboard w={24} m={-6} />
          Activity Feed
        </StyledNavLink>
      )}

      {allowed_features.access_to_helios_admin_page && (
        <StyledNavLink to="/helios-admin" data-test="admin" className={classes.centered}>
          <SupervisorAccountIcon className={classes.financialsIc} />
          Helios Admin
        </StyledNavLink>
      )}

      {access_customers_page && (
        <StyledNavLink to="/customers" data-test="customers">
          <SVG.SVGCustomer w={24} m={-6} />
          Customers
        </StyledNavLink>
      )}

      {allowed_features.access_rithmic_sftp_server && (
        <StyledNavLink to="/rithmic-files" className={classes.centered}>
          <FileCopyOutlinedIcon className={classes.financialsIc} />
          Rithmic files
        </StyledNavLink>
      )}

      {access_rithmic_accounts && (
        <StyledNavLink
          to="/rithmicAccounts"
          className={classes.centered}
          data-test="rithmicAccounts"
        >
          <PresentToAllIcon className={classes.financialsIc} />
          Rithmic accounts
        </StyledNavLink>
      )}

      {allowed_features.access_rithmic_historical_orders && (
        <StyledNavLink to="/rithmicHistoricalOrders" className={classes.centered}>
          <HistoryIcon className={classes.financialsIc} />
          Rithmic historical orders
        </StyledNavLink>
      )}

      {allowed_features.access_financials_info && (
        <StyledNavLink to="/Financials" className={classes.centered} data-test="financials-link">
          <PaymentIcon className={classes.financialsIc} />
          Financials
        </StyledNavLink>
      )}

      {(access_all_features || access_to_discounts_tab) && (
        <StyledNavLink to="/discounts" data-test="discounts">
          <SVG.SVGDiscount w={24} m={-6} />
          Discounts
        </StyledNavLink>
      )}

      {access_to_watchlist_tab && (
        <StyledNavLink className={classes.centered} to="/compliance-watchlist">
          <VisibilityIcon className={classes.financialsIc} />
          Watchlist
        </StyledNavLink>
      )}

      {access_to_compliance_search_page && (
        <StyledNavLink className={classes.centered} to="/compliance-search">
          <SearchIcon className={classes.financialsIc} />
          Compliance search
        </StyledNavLink>
      )}

      {allowed_features.access_agents_page && (
        <StyledNavLink to="/agents" data-test="agents-link">
          <SVG.SVGAdmin w={24} m={-6} />
          Agents
        </StyledNavLink>
      )}

      {allowed_features.access_customers_page && (
        <StyledNavLink className={classes.centered} to="/xlsx-customers-data">
          <ListIcon className={classes.financialsIc} />
          XLSX customers data
        </StyledNavLink>
      )}

      {(access_all_features || allowed_features.access_to_evaluations_tab) && (
        <StyledNavLink to="/evaluations" className={classes.centered} data-test="evaluations">
          <SVG.Checklist margin="0 9px 0 0" />
          Evaluations
        </StyledNavLink>
      )}

      {allowed_features.access_funded_traders && (
        <StyledNavLink to="/fundedTraders" className={classes.centered} data-test="fundedTraders">
          <SVG.Funds margin="0 9px 0 0" />
          Funded Traders
        </StyledNavLink>
      )}

      {allowed_features.access_withdrawals && (
        <StyledNavLink
          to="/withdrawalRequests"
          className={classes.centered}
          data-test="withdrawalRequests"
        >
          <SVG.Withdrawals w={24} margin="0 9px 0 0" />
          Withdrawals
        </StyledNavLink>
      )}

      {allowed_features.access_manage_affiliate_accounts && (
        <StyledNavLink to="/affiliates" className={classes.affiliateContainerIc}>
          <SVG.Handshake />
          <div className={classes.affiliateTextContainer}>Affiliates</div>
        </StyledNavLink>
      )}

      {allowed_features.access_journeys && (
        <StyledNavLink to="/journeys" className={classes.centered} data-test="journeys-link">
          <TimelineIcon className={classes.financialsIc} />
          Journeys
        </StyledNavLink>
      )}

      {allowed_features.access_user_messages && (
        <StyledNavLink to="/messages" data-test="messages-link">
          <SVG.MessageIcon className={classes.messageIcon} />
          User messages
        </StyledNavLink>
      )}

      <a
        className={classes.affiliateContainerIc}
        href="https://adminsetup.heliostp.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <PersonAddIcon className={classes.financialsIc} />
        LiveSim Creation
      </a>

      <StyledNavLink to="/login" onClick={() => login()}>
        <SVG.SVGLogout w={24} m={-6} />
        Logout
      </StyledNavLink>

      {allowed_features.access_read_docs && (
        <a
          href="https://docs.google.com/document/d/1uZnFnFS0DFL6KK59OKmInm0DaSh3_3soTQIXcXlsC3Q/edit"
          target="_blank"
          rel="noopener noreferrer"
          data-test="docs-link"
        >
          Docs
        </a>
      )}
    </div>
  );
};

export default SideNav;
