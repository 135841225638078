import React, { useCallback, useEffect, useState } from "react";
import moment from "moment-timezone";
import * as LogRocket from "logrocket";
import { useHistory, useParams } from "react-router-dom";

import { SVGArrow } from "../../components/svgs";
import { useAuthContext } from "../../context";
import { APIDiscounts } from "../../api";
import { IDiscountCodeAccount } from "../../api/discounts/index.types";
import usePagination from "../../hooks/usePagination";
import useStyles from "./useStyles";
import { DATE_FORMAT } from "../../constants";
import { ContainerWithLoader, PaginationWithLimit } from "../../components/ui";

const Discount = (): React.ReactElement => {
  LogRocket.track("Discounts: Copy/ View Code");
  const params: { id: string } = useParams();
  const id = params.id;
  const classes = useStyles();
  const history = useHistory();
  const pagination = usePagination();
  const { loginAndQuery } = useAuthContext();
  const handleOnClick = useCallback(
    (user_id: number) => history.push(`/customer/${user_id}`),
    [history]
  );
  const handleArrowClick = useCallback(() => history.push("/discounts"), [history]);

  const [discounts, setDiscounts] = useState<IDiscountCodeAccount[]>([]);
  const [search, setSearch] = useState("");
  const [planName, setPlanName] = useState<string | null>("");
  const [isLoading, setLoading] = useState(false);
  const getDiscount = async () => {
    const data = {
      limit: pagination.limit,
      page: pagination.page,
      contains: search.toLowerCase(),
      code: id,
    };

    try {
      setLoading(true);
      const response = await APIDiscounts.getDiscountCodesByUsedUserRequest(data);
      const { billing_histories, count, plan_name } = response.data;
      plan_name && setPlanName(plan_name.name);
      setDiscounts(billing_histories);
      pagination.setCount(count);
    } catch (error) {
      console.error("Error fetching discount codes by user", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSearch = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => setSearch(target.value),
    []
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      loginAndQuery(getDiscount);
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [search, pagination.limit, pagination.page]);

  return (
    <div className="discounts">
      <div className="title">
        <SVGArrow onClick={handleArrowClick} />
        {id} <div className={classes.planName}>Plan: {planName || "???"}</div>
      </div>
      <input type="text" value={search} onChange={handleSearch} placeholder="Search..." />

      <ContainerWithLoader isLoading={isLoading} report={discounts}>
        <table className={classes.tableContainer}>
          <thead>
            <tr>
              <td>Name</td>
              <td>Email</td>
              <td>IP</td>
              <td>Used Date</td>
            </tr>
          </thead>

          <tbody>
            {discounts.map(({ account, ip, start, purchase_date }) =>
              account ? (
                <tr className={classes.tableRow} key={account?.id}>
                  <td>
                    <div onClick={() => handleOnClick(account?.id)} tabIndex={-1} role="button">
                      {account?.name}
                    </div>
                  </td>
                  <td>
                    <div onClick={() => handleOnClick(account?.id)} role="button" tabIndex={-1}>
                      {account?.email}
                    </div>
                  </td>
                  <td>
                    <a
                      className={classes.ip}
                      href={`https://whatismyipaddress.com/ip/${ip}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {ip}
                    </a>
                  </td>
                  <td>
                    {start
                      ? start
                      : moment(purchase_date).tz("America/Chicago").format(DATE_FORMAT)}
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </ContainerWithLoader>

      <div>
        <PaginationWithLimit
          count={pagination.count}
          limit={pagination.limit}
          setPageState={pagination.setPage}
          page={pagination.page}
          setLimit={pagination.setLimit}
        />
      </div>
    </div>
  );
};

export default Discount;
