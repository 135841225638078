import moment from "moment-timezone";
import * as Yup from "yup";

import { ICreateWithdrawalWithOnHoldProp } from ".";

export const initialWithdrawalsValue: ICreateWithdrawalWithOnHoldProp = {
  amount_requested: 0,
  account_balance: 0,
  watermark_level: 0,
  manual_deduction: 0,
  manual_amount_interest_rate: 20,
  manual_withdrawal_amount: 0,
  initialization_date: moment().format(),
  isOnHold: false,
  net_withdrawal_amount: 0,
  traded_accounts_id: 0,
  accounts_id: 0,
};

export const withdrawalsSchema = Yup.object().shape({
  amount_requested: Yup.number().required("Amount is required").min(1),
  account_balance: Yup.number().required("Amount is required").min(1),
  watermark_level: Yup.number().required("Amount is required").min(0),
  manual_deduction: Yup.number().required("Amount is required").min(0),
  manual_amount_interest_rate: Yup.number().required("New amount is required").min(0),
  traded_accounts_id: Yup.number()
    .required("Account ID is required")
    .min(1, "Account ID is required"),
  manual_withdrawal_amount: Yup.string().required("Manual amount is required").min(0),
  initialization_date: Yup.date(),
});
