import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RadioGroup, Radio, FormControl, FormControlLabel, FormLabel } from "@material-ui/core";
import SelectByLiveSimOrLive, {
  TTypeAccount,
} from "../../../../components/ui/SelectByLiveSimOrLive";
import SelectByTimeFrame, { TTimeFrame } from "../../../../components/ui/SelectByTimeFrame";
import SelectByDateWithCustomPeriod from "../../../../components/ui/SelectByDateWithCustomPeriod";
import { DATES_VALUE } from "../../../../constants";
import useStyles from "./useStyles";
import Select from "../../../../components/ui/Select";

type FiltersByDateAndTypesProps = {
  onDateChange: (startDate: string, endDate: string, isAllTime: boolean) => void;
} & (
  | {
      timeFrame: TTimeFrame;
      onTimeFrameChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    }
  | {
      timeFrame?: null;
      onTimeFrameChange?: null;
    }
) &
  (
    | {
        reportType: "cases" | "amount";
        onReportTypeChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
      }
    | {
        reportType?: null;
        onReportTypeChange?: null;
      }
  ) &
  (
    | {
        slippagePeriod: number;
        handleChangeSlippagePeriod: (event: React.ChangeEvent<{ value: unknown }>) => void;
      }
    | {
        slippagePeriod?: null;
        handleChangeSlippagePeriod?: null;
      }
  ) &
  (
    | {
        typeAccount: TTypeAccount;
        onTypeAccountChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
      }
    | {
        typeAccount?: null;
        onTypeAccountChange?: null;
      }
  ) &
  (
    | {
        plan: TTypeAccount;
        onPlanChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
      }
    | {
        plan?: null;
        onPlanChange?: null;
      }
  );

const FiltersByDateAndTypes: React.FC<FiltersByDateAndTypesProps> = ({
  timeFrame,
  typeAccount,
  reportType,
  slippagePeriod,
  plan,
  onTimeFrameChange,
  onTypeAccountChange,
  onReportTypeChange,
  onDateChange,
  handleChangeSlippagePeriod,
  onPlanChange,
}) => {
  const classes = useStyles();

  const { plans } = useSelector((state) => state);

  const isShowSlippagePeriod = !!handleChangeSlippagePeriod;
  const isShowReportType = !!onReportTypeChange;
  const isShowTimeFrame = !!onTimeFrameChange && timeFrame;
  const isShowTypeAccount = !!onTypeAccountChange && typeAccount;
  const isShowPlan = !!onPlanChange;

  const [plansOptions, setPlansOptions] = useState<{ text: string; name: string }[]>([]);

  useEffect(() => {
    if (plans.data.plans.length && !plans.loading) {
      const avialablePlansOptions = plans.data.plans
        .filter((plan) => {
          if (
            ["GAU50", "GAU100", "GAU150", "GAU200", "TCP25", "TCP50", "TCP100"].includes(plan.key)
          ) {
            return plan;
          }
          return null;
        })
        .map((plan) => ({
          text: plan.name,
          name: plan.key,
        }));

      setPlansOptions(avialablePlansOptions);
    }
  }, [plans]);

  return (
    <div className={classes.container}>
      <div>
        <SelectByDateWithCustomPeriod
          setIsLoading={() => void null}
          getReport={(startDate, endDate, isAllTime) => {
            onDateChange(startDate, endDate, !!isAllTime);
          }}
          setReport={() => void null}
          initialDateBy={DATES_VALUE.thisWeek}
        />
      </div>

      {isShowTimeFrame && (
        <div className={classes.timeFrameContainer}>
          <SelectByTimeFrame
            value={timeFrame}
            onChange={onTimeFrameChange}
            {...(isShowSlippagePeriod && {
              excludeOptions: ["day"],
            })}
          />
        </div>
      )}

      {isShowTypeAccount && (
        <div className={classes.accountTypeContainer}>
          <SelectByLiveSimOrLive value={typeAccount} onChange={onTypeAccountChange} />
        </div>
      )}
      {isShowSlippagePeriod && (
        <div className={classes.timeFrameContainer}>
          <Select
            label="Slippage period"
            inputLabel="Slippage period"
            value={slippagePeriod}
            onChange={handleChangeSlippagePeriod}
            options={[
              { text: "0", name: 0 },
              { text: "1", name: 1 },
              { text: "2", name: 2 },
              { text: "3", name: 3 },
              { text: "4", name: 4 },
              { text: "5", name: 5 },
            ]}
          />
        </div>
      )}

      {isShowReportType && (
        <div className={classes.typeContainer}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Report type</FormLabel>
            <RadioGroup row onChange={onReportTypeChange} value={reportType}>
              <FormControlLabel color="primary" value="cases" control={<Radio />} label="cases" />
              <FormControlLabel color="primary" value="amount" control={<Radio />} label="amount" />
            </RadioGroup>
          </FormControl>
        </div>
      )}

      {isShowPlan && plansOptions.length && (
        <div className={classes.planContainer}>
          <Select
            label="Plan"
            inputLabel="Plan"
            value={plan}
            onChange={onPlanChange}
            options={plansOptions}
          />
        </div>
      )}
    </div>
  );
};

interface IValues {
  startDate: string;
  endDate: string;
  isAllTime: boolean;
  timeFrame: TTimeFrame;
  typeAccount: TTypeAccount;
  reportType: "cases" | "amount";
  slippagePeriod: number;
  plan: string;
}

interface IHandlers {
  handleChangeTimeFrame: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleChangeTypeAccount: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleChangeReportType: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleDateChange: (startDate: string, endDate: string, isAllTime: boolean) => void;
  handleChangeSlippagePeriod: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handlePlanChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const useSearchValues = (
  initialValues = { initialTimeFrame: "day" }
): {
  values: IValues;
  handlers: IHandlers;
} => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllTime, setIsAllTime] = useState(false);
  const [timeFrame, setTimeFrame] = useState<TTimeFrame>(initialValues.initialTimeFrame);
  const [typeAccount, setTypeAccount] = useState<TTypeAccount>("all");
  const [reportType, setReportType] = useState<"cases" | "amount">("cases");
  const [slippagePeriod, setSlippagePeriod] = useState<number>(0);
  const [plan, setPlan] = useState<string>("GAU50");

  const handleChangeTimeFrame = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTimeFrame(event.target.value as TTimeFrame);
  };
  const handleChangeTypeAccount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTypeAccount(event.target.value as TTypeAccount);
  };
  const handleChangeReportType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setReportType(event.target.value as "cases" | "amount");
  };
  const handleDateChange = (startDate: string, endDate: string, isAllTime: boolean) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsAllTime(isAllTime);
  };
  const handleChangeSlippagePeriod = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSlippagePeriod(event.target.value as number);
  };
  const handlePlanChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPlan(event.target.value as string);
  };

  return {
    values: {
      startDate,
      endDate,
      isAllTime,
      timeFrame,
      typeAccount,
      reportType,
      slippagePeriod,
      plan,
    },
    handlers: {
      handleChangeTimeFrame,
      handleChangeTypeAccount,
      handleChangeReportType,
      handleDateChange,
      handleChangeSlippagePeriod,
      handlePlanChange,
    },
  };
};

export default FiltersByDateAndTypes;
