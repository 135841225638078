import {
  ISalesStatsByDayResponse,
  IGetWithdrawalStatsResponse,
  IGetTIFStatsResponse,
  IGetWithdrawalRatesResponse,
  IGetTIFRegistrationByLocationResponse,
  IGetSalesResultsInScalesResponse,
} from "../../api/billing/index.types";

export type FinancialsAction =
  //SalesByDays
  | FinancialsActionsSalesByDaysFetchRequest
  | FinancialsActionsSalesByDaysFetchSuccess
  | FinancialsActionsSalesByDaysFetchFailed
  // Withdrawal stats
  | FinancialsActionsWithdrawalStatsFetchRequest
  | FinancialsActionsWithdrawalStatsFetchSuccess
  | FinancialsActionsWithdrawalStatsFetchFailed
  // TIF stats
  | FinancialsActionsTIFStatsFetchRequest
  | FinancialsActionsTIFStatsFetchSuccess
  | FinancialsActionsTIFStatsFetchFailed
  // Withdrawal per requests
  | FinancialsActionsWithdrawalPerRequestsFetchRequest
  | FinancialsActionsWithdrawalPerRequestsFetchSuccess
  | FinancialsActionsWithdrawalPerRequestsFetchFailed
  // Withdrawal per TIF registration
  | FinancialsActionsWithdrawalPerTIFRegistrationFetchRequest
  | FinancialsActionsWithdrawalPerTIFRegistrationFetchSuccess
  | FinancialsActionsWithdrawalPerTIFRegistrationFetchFailed
  //Withdrawal per Sales
  | FinancialsActionsWithdrawalPerSalesFetchRequest
  | FinancialsActionsWithdrawalPerSalesFetchSuccess
  | FinancialsActionsWithdrawalPerSalesFetchFailed
  // TIF registration by location
  | FinancialsActionsTIFRegistrationByLocationFetchRequest
  | FinancialsActionsTIFRegistrationByLocationFetchSuccess
  | FinancialsActionsTIFRegistrationByLocationFetchFailed
  // Sales results in scales
  | FinancialsActionsSalesResultsInScalesFetchRequest
  | FinancialsActionsSalesResultsInScalesFetchSuccess
  | FinancialsActionsSalesResultsInScalesFetchFailed;

//SalesByDays
export interface FinancialsActionsSalesByDaysFetchRequest {
  type: FinancialsActionType.fetchFinancialsSalesByDaysRequest;
}
export interface FinancialsActionsSalesByDaysFetchSuccess {
  type: FinancialsActionType.fetchFinancialsSalesByDaysSuccess;
  payload: ISalesStatsByDayResponse["data"]["report"];
}
export interface FinancialsActionsSalesByDaysFetchFailed {
  type: FinancialsActionType.fetchFinancialsSalesByDaysFailed;
}
// Withdrawal stats
export interface FinancialsActionsWithdrawalStatsFetchRequest {
  type: FinancialsActionType.fetchFinancialsWithdrawalStatsRequest;
}
export interface FinancialsActionsWithdrawalStatsFetchSuccess {
  type: FinancialsActionType.fetchFinancialsWithdrawalStatsSuccess;
  payload: IGetWithdrawalStatsResponse["data"]["report"];
}
export interface FinancialsActionsWithdrawalStatsFetchFailed {
  type: FinancialsActionType.fetchFinancialsWithdrawalStatsFailed;
}
// TIF stats
export interface FinancialsActionsTIFStatsFetchRequest {
  type: FinancialsActionType.fetchFinancialsTIFStatsRequest;
}
export interface FinancialsActionsTIFStatsFetchSuccess {
  type: FinancialsActionType.fetchFinancialsTIFStatsSuccess;
  payload: IGetTIFStatsResponse["data"]["report"];
}
export interface FinancialsActionsTIFStatsFetchFailed {
  type: FinancialsActionType.fetchFinancialsTIFStatsFailed;
}
// Withdrawal per requests
export interface FinancialsActionsWithdrawalPerRequestsFetchRequest {
  type: FinancialsActionType.fetchFinancialsWithdrawalPerRequestsRequest;
}
export interface FinancialsActionsWithdrawalPerRequestsFetchSuccess {
  type: FinancialsActionType.fetchFinancialsWithdrawalPerRequestsSuccess;
  payload: IGetWithdrawalRatesResponse["data"];
}
export interface FinancialsActionsWithdrawalPerRequestsFetchFailed {
  type: FinancialsActionType.fetchFinancialsWithdrawalPerRequestsFailed;
}
// Withdrawal per TIF registration
export interface FinancialsActionsWithdrawalPerTIFRegistrationFetchRequest {
  type: FinancialsActionType.fetchFinancialsWithdrawalPerTIFRegistrationRequest;
}
export interface FinancialsActionsWithdrawalPerTIFRegistrationFetchSuccess {
  type: FinancialsActionType.fetchFinancialsWithdrawalPerTIFRegistrationSuccess;
  payload: IGetWithdrawalRatesResponse["data"];
}
export interface FinancialsActionsWithdrawalPerTIFRegistrationFetchFailed {
  type: FinancialsActionType.fetchFinancialsWithdrawalPerTIFRegistrationFailed;
}
// Withdrawal per Sales
export interface FinancialsActionsWithdrawalPerSalesFetchRequest {
  type: FinancialsActionType.fetchFinancialsWithdrawalPerSalesRequest;
}
export interface FinancialsActionsWithdrawalPerSalesFetchSuccess {
  type: FinancialsActionType.fetchFinancialsWithdrawalPerSalesSuccess;
  payload: IGetWithdrawalRatesResponse["data"];
}
export interface FinancialsActionsWithdrawalPerSalesFetchFailed {
  type: FinancialsActionType.fetchFinancialsWithdrawalPerSalesFailed;
}
// TIF registration by location
export interface FinancialsActionsTIFRegistrationByLocationFetchRequest {
  type: FinancialsActionType.fetchFinancialsTIFRegistrationByLocationRequest;
}
export interface FinancialsActionsTIFRegistrationByLocationFetchSuccess {
  type: FinancialsActionType.fetchFinancialsTIFRegistrationByLocationSuccess;
  payload: IGetTIFRegistrationByLocationResponse["data"]["report"];
}
export interface FinancialsActionsTIFRegistrationByLocationFetchFailed {
  type: FinancialsActionType.fetchFinancialsTIFRegistrationByLocationFailed;
}
// Sales results in scales
export interface FinancialsActionsSalesResultsInScalesFetchRequest {
  type: FinancialsActionType.fetchFinancialsSalesResultsInScalesRequest;
}
export interface FinancialsActionsSalesResultsInScalesFetchSuccess {
  type: FinancialsActionType.fetchFinancialsSalesResultsInScalesSuccess;
  payload: IGetSalesResultsInScalesResponse["data"]["report"];
}
export interface FinancialsActionsSalesResultsInScalesFetchFailed {
  type: FinancialsActionType.fetchFinancialsSalesResultsInScalesFailed;
}

export enum FinancialsActionType {
  //SalesByDays
  fetchFinancialsSalesByDaysRequest = "financials/fetchFinancialsSalesByDaysRequest",
  fetchFinancialsSalesByDaysSuccess = "financials/fetchFinancialsSalesByDaysSuccess",
  fetchFinancialsSalesByDaysFailed = "financials/fetchFinancialsSalesByDaysFailed",
  // Withdrawal stats
  fetchFinancialsWithdrawalStatsRequest = "financials/fetchFinancialsWithdrawalStatsRequest",
  fetchFinancialsWithdrawalStatsSuccess = "financials/fetchFinancialsWithdrawalStatsSuccess",
  fetchFinancialsWithdrawalStatsFailed = "financials/fetchFinancialsWithdrawalStatsFailed",
  // TIF stats
  fetchFinancialsTIFStatsRequest = "financials/fetchFinancialsTIFStatsRequest",
  fetchFinancialsTIFStatsSuccess = "financials/fetchFinancialsTIFStatsSuccess",
  fetchFinancialsTIFStatsFailed = "financials/fetchFinancialsTIFStatsFailed",
  // Withdrawal per requests
  fetchFinancialsWithdrawalPerRequestsRequest = "financials/fetchFinancialsWithdrawalPerRequestsRequest",
  fetchFinancialsWithdrawalPerRequestsSuccess = "financials/fetchFinancialsWithdrawalPerRequestsSuccess",
  fetchFinancialsWithdrawalPerRequestsFailed = "financials/fetchFinancialsWithdrawalPerRequestsFailed",
  // Withdrawal per TIF registration
  fetchFinancialsWithdrawalPerTIFRegistrationRequest = "financials/fetchFinancialsWithdrawalPerTIFRegistrationRequest",
  fetchFinancialsWithdrawalPerTIFRegistrationSuccess = "financials/fetchFinancialsWithdrawalPerTIFRegistrationSuccess",
  fetchFinancialsWithdrawalPerTIFRegistrationFailed = "financials/fetchFinancialsWithdrawalPerTIFRegistrationFailed",
  // Withdrawal per Sales
  fetchFinancialsWithdrawalPerSalesRequest = "financials/fetchFinancialsWithdrawalPerSalesRequest",
  fetchFinancialsWithdrawalPerSalesSuccess = "financials/fetchFinancialsWithdrawalPerSalesSuccess",
  fetchFinancialsWithdrawalPerSalesFailed = "financials/fetchFinancialsWithdrawalPerSalesFailed",
  // TIF registration by location
  fetchFinancialsTIFRegistrationByLocationRequest = "financials/fetchFinancialsTIFRegistrationByLocationRequest",
  fetchFinancialsTIFRegistrationByLocationSuccess = "financials/fetchFinancialsTIFRegistrationByLocationSuccess",
  fetchFinancialsTIFRegistrationByLocationFailed = "financials/fetchFinancialsTIFRegistrationByLocationFailed",
  // Sales results in scales
  fetchFinancialsSalesResultsInScalesRequest = "financials/fetchFinancialsSalesResultsInScalesRequest",
  fetchFinancialsSalesResultsInScalesSuccess = "financials/fetchFinancialsSalesResultsInScalesSuccess",
  fetchFinancialsSalesResultsInScalesFailed = "financials/fetchFinancialsSalesResultsInScalesFailed",
}

export type IFinancialsState = {
  sales_by_days: {
    data: ISalesStatsByDayResponse["data"]["report"];
    loading: boolean;
  };
  withdrawal_stats: {
    data: IGetWithdrawalStatsResponse["data"]["report"];
    loading: boolean;
  };
  tif_stats: {
    data: IGetTIFStatsResponse["data"]["report"];
    loading: boolean;
  };
  withdrawal_per_requests: {
    data: IGetWithdrawalRatesResponse["data"];
    loading: boolean;
  };
  withdrawal_per_tif_registration: {
    data: IGetWithdrawalRatesResponse["data"];
    loading: boolean;
  };
  withdrawal_per_sales: {
    data: IGetWithdrawalRatesResponse["data"];
    loading: boolean;
  };
  tif_registration_by_location: {
    data: IGetTIFRegistrationByLocationResponse["data"]["report"];
    loading: boolean;
  };
  sales_results_in_scales: {
    data: IGetSalesResultsInScalesResponse["data"]["report"];
    loading: boolean;
  };
};
