import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dropZoneContainer: {
    marginTop: 10,
  },
  missedDay: {
    "& .MuiTypography-colorInherit": {
      color: "#ff00006b",
    },
  },
  futureDay: {
    "& .MuiTypography-colorInherit": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
  filterContainer: {
    display: "flex",
  },
  downloadButton: {
    marginLeft: 10,
  },
});

export default useStyles;
