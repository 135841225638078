import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import { FinancialsActions } from "../../../../reducers/financialsReducer/actions";
import { BillingAPI, BILLING_API_URLS } from "../../../../api/billing";
import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import Table from "../../../ComplianceSearch/components/Table";
import { IReport, buildColumns, buildReport } from "../utils";
import FiltersByDateAndTypes, { useSearchValues } from "../FiltersByDateAndTypes";

const TIFStats: React.FC = () => {
  const dispatch = useDispatch();
  const { values, handlers } = useSearchValues();

  const [report, setReport] = useState<IReport[]>([]);
  const [columns, setColumns] = useState<IColum[]>([]);

  const { data, loading } = useSelector((state) => state.financials.tif_stats);

  useQuery({
    enabled: !!values.startDate || !!values.endDate,
    queryKey: [
      BILLING_API_URLS.tifStats,
      values.startDate,
      values.endDate,
      values.isAllTime,
      values.timeFrame,
      values.typeAccount,
    ],
    async queryFn({ signal }) {
      dispatch(FinancialsActions.fetchFinancialsTIFStatsRequest());
      const query = new URLSearchParams();
      query.append("startDate", values.startDate);
      query.append("endDate", values.endDate);
      query.append("isAllTime", String(values.isAllTime));
      query.append("timeFrame", values.timeFrame);
      query.append("account_type", values.typeAccount);

      const response = await BillingAPI.getTIFStatsRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsTIFStatsSuccess(response.data.report));
      return response;
    },
    onError() {
      console.error("Error fetching TIF stats");
      dispatch(FinancialsActions.fetchFinancialsTIFStatsFailed());
    },
  });

  useEffect(() => {
    const dates = Array.from(new Set(data.map((data) => data.period)));
    setColumns(buildColumns({ dates }));
    setReport(buildReport({ data, dates }));
  }, [data]);

  return (
    <div>
      <h2>TIF stats</h2>
      <FiltersByDateAndTypes
        timeFrame={values.timeFrame}
        typeAccount={values.typeAccount}
        onTimeFrameChange={handlers.handleChangeTimeFrame}
        onTypeAccountChange={handlers.handleChangeTypeAccount}
        onDateChange={handlers.handleDateChange}
      />

      <div>
        <Table
          csv_table_name="TIF stats"
          columns={columns}
          loading={loading}
          data={report}
          extractKey={(row) => row.plan}
        />
      </div>
    </div>
  );
};

export default TIFStats;
