import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core/";
import { EProcessingStatuses, PROCESSING_STATUSES_OPTIONS } from "../../../../constants";

const useStyles = makeStyles({
  formControlContainer: {
    minWidth: 200,
    maxWidth: 250,
  },
});

const processingStatusOptions = PROCESSING_STATUSES_OPTIONS.filter(
  ({ value }) => !["Failed", "Completed"].includes(value)
).map(({ value }) => value);

const initialFilters = ["all", "active", "account_in_setup"];

interface IProps {
  handleSearchChange: (
    processing_status: string[],
    isShowActive: boolean,
    isShowFailed: boolean
  ) => void;
}

const ProcessingStatusFilterOptions: React.FC<IProps> = ({ handleSearchChange }) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(initialFilters);
  const [selectedProcessingOptions, setSelectedProcessingOptions] =
    useState<string[]>(processingStatusOptions);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    if (
      processingStatusOptions.includes(value[0] as EProcessingStatuses) ||
      value.includes("Failed") ||
      value.includes("Completed")
    ) {
      if (selectedProcessingOptions.includes(value[0])) {
        const newSelectedOptions = selectedProcessingOptions.filter(
          (option) => option !== value[0]
        );
        setSelectedProcessingOptions(newSelectedOptions);
      } else {
        setSelectedProcessingOptions([...selectedProcessingOptions, ...value]);
      }
      return;
    }

    if (value.includes("account_in_setup")) {
      if (selectedOptions.includes("account_in_setup")) {
        const newSelectedOptions = selectedOptions.filter((v) => v !== "account_in_setup");
        setSelectedOptions(newSelectedOptions);
        setSelectedProcessingOptions([]);
        return;
      } else {
        setSelectedOptions([...selectedOptions, "account_in_setup"]);
        setSelectedProcessingOptions(processingStatusOptions);
        return;
      }
    }

    if (value.includes("all")) {
      if (selectedOptions.includes("all")) {
        setSelectedOptions([]);
        setSelectedProcessingOptions([]);
      } else {
        setSelectedOptions(initialFilters);
        setSelectedProcessingOptions(processingStatusOptions);
      }
      return;
    }

    if (selectedOptions.includes(value[0])) {
      const newSelectedOptions = selectedOptions.filter((option) => option !== value[0]);
      setSelectedOptions(newSelectedOptions);
    } else {
      setSelectedOptions([...selectedOptions, ...value]);
    }
  };

  useEffect(() => {
    handleSearchChange(
      selectedProcessingOptions,
      selectedOptions.includes("active"),
      selectedOptions.includes("failed")
    );
  }, [selectedOptions, selectedProcessingOptions]);

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControlContainer}>
        <InputLabel>Processing status</InputLabel>
        <Select onChange={handleChange} multiple value={[]}>
          <MenuItem value="all">
            <Checkbox checked={selectedOptions.includes("all")} color="primary" />
            <ListItemText primary="All" />
          </MenuItem>
          <MenuItem value="active">
            <Checkbox checked={selectedOptions.includes("active")} color="primary" />
            <ListItemText primary="Active" />
          </MenuItem>

          <MenuItem value="account_in_setup">
            <Checkbox checked={selectedOptions.includes("account_in_setup")} color="primary" />
            <ListItemText primary="Account in setup" />
          </MenuItem>

          {PROCESSING_STATUSES_OPTIONS.map(({ value, label, id }) => {
            if (["Completed", "Failed"].includes(value)) return null;
            return (
              <MenuItem key={id} value={value} style={{ marginLeft: 30 }}>
                <Checkbox checked={selectedProcessingOptions.includes(value)} color="primary" />
                <ListItemText primary={label} />
              </MenuItem>
            );
          })}

          <MenuItem value="Completed">
            <Checkbox checked={selectedProcessingOptions.includes("Completed")} color="primary" />
            <ListItemText primary="Completed" />
          </MenuItem>

          <MenuItem value="Failed">
            <Checkbox checked={selectedProcessingOptions.includes("Failed")} color="primary" />
            <ListItemText primary="Failed" />
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default ProcessingStatusFilterOptions;
