import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dropZoneContainer: {
    marginTop: 50,
  },
  error: {
    color: "#e53935",
  },
});

export default useStyles;
