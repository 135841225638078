import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import ChartBar from "../../../../components/ui/ChartBar";
import { IDatasets } from "../ReportChartsWithDateSelect/Components/Bar";
import { CENTS_IN_DOLLAR, COLOR_PALETTE } from "../../../../constants";
import { FinancialsActions } from "../../../../reducers/financialsReducer/actions";
import { ContainerWithLoader } from "../../../../components/ui";
import { BILLING_API_URLS, BillingAPI } from "../../../../api/billing";
import FiltersByDateAndTypes, { useSearchValues } from "../FiltersByDateAndTypes";
import { IntlFormatUSD } from "../../../../utils";

const CHART_BAR_EXTERNAL_OPTIONS = {
  plugins: {
    datalabels: {
      formatter(value: number) {
        if (!value) {
          return null;
        }
        return IntlFormatUSD(value, { maximumSignificantDigits: 6 });
      },
    },
  },
};

const SalesResultsInScales: React.FC = () => {
  const { values, handlers } = useSearchValues();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.financials.sales_results_in_scales);

  const [incomeBarData, setIncomeBarData] = useState<Record<string, unknown>>({
    labels: [],
    datasets: [],
  });
  const [casesBarData, setCasesBarData] = useState<Record<string, unknown>>({
    labels: [],
    datasets: [],
  });

  useQuery({
    enabled: !!values.startDate || !!values.endDate,
    queryKey: [
      BILLING_API_URLS.salesResultsInScales,
      values.startDate,
      values.endDate,
      values.plan,
      values.isAllTime,
    ],
    queryFn: async ({ signal }) => {
      const query = new URLSearchParams();
      query.append("plan", values.plan);
      query.append("endDate", values.endDate);
      query.append("startDate", values.startDate);
      query.append("isAllTime", values.isAllTime ? "true" : "false");

      dispatch(FinancialsActions.fetchFinancialsSalesResultsInScalesRequest());
      const response = await BillingAPI.getSalesResultsInScalesRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsSalesResultsInScalesSuccess(response.data.report));
      return response;
    },
    onError: () => {
      dispatch(FinancialsActions.fetchFinancialsSalesResultsInScalesFailed());
    },
  });

  useEffect(() => {
    {
      const labels = data.map((el) => el.price_range);
      const datasets: IDatasets[] = [
        {
          label: "Income",
          data: data.map((el) => Number((el.total_income / CENTS_IN_DOLLAR).toFixed(2))),
          backgroundColor: COLOR_PALETTE[22],
        },
      ];
      setIncomeBarData({ labels, datasets });
    }
    {
      const labels = data.map((el) => el.price_range);
      const datasets: IDatasets[] = [
        {
          label: "Cases",
          data: data.map((el) => el.cases),
          backgroundColor: COLOR_PALETTE[22],
        },
      ];
      setCasesBarData({ labels, datasets });
    }
  }, [data]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2>Sales results in scales</h2>
          <FiltersByDateAndTypes
            onDateChange={handlers.handleDateChange}
            plan={values.plan}
            onPlanChange={handlers.handlePlanChange}
          />
        </Grid>
      </Grid>

      <ContainerWithLoader isLoading={loading} report={data}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <ChartBar
              barData={incomeBarData}
              height={125}
              externalOptions={CHART_BAR_EXTERNAL_OPTIONS}
            />
          </Grid>
          <Grid item xs={6}>
            <ChartBar barData={casesBarData} height={125} />
          </Grid>
        </Grid>
      </ContainerWithLoader>
    </div>
  );
};

export default SalesResultsInScales;
