import React from "react";
import * as LogRocket from "logrocket";
import MomentUtils from "@date-io/moment";
import { Route, Switch, useLocation } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ErrorBoundary } from "react-error-boundary";

import "./App.scss";
import { isProduction } from "./constants";
import * as Page from "./pages";
import { Private, SideNav, AppState, ErrorBoundaryFallback } from "./components/ui";
import {
  FeedProvider,
  CustomerProvider,
  DiscountsProvider,
  SearchCustomersProvider,
  IntercomProvider,
  RithmicAccountsProvider,
  DiscountCodesLogsProvider,
  AgentsLogsHistoryProvider,
  EvaluationsProvider,
  useAdminProviderDataContext,
  ComplianceSearchProvider,
} from "./context";

if (isProduction) {
  LogRocket.init("earn2trade/e2t-admin-panel");
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#1288e5",
    },
    secondary: {
      main: "#e53935",
    },
    error: {
      main: "#e53935",
    },
    warning: {
      main: "#ff9800",
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        "&.Mui-error": {
          marginLeft: 0,
          marginTop: -3,
        },
      },
    },
    // MuiPaper: {
    //   root: {
    //     backgroundColor: "#f2f2f2",
    //   },
    // },
    MuiTabs: {
      root: {
        marginBottom: 5,
        backgroundColor: "#f2f2f2",
      },
      indicator: {
        backgroundColor: "#1288e5",
      },
    },
  },
});

const App = (): React.ReactElement => {
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const { pathname } = useLocation();

  const {
    access_all_features,
    access_activity_feed_page,
    access_customers_page,
    read_agents_log,
    access_rithmic_accounts,
    access_rithmic_sftp_server,
    access_to_discounts_tab,
    access_to_watchlist_tab,
    access_to_compliance_search_page,
  } = allowed_features;

  if (pathname === "/app-logs") {
    return (
      <Switch>
        <Private path="/app-logs">
          <Page.AppLogs />
        </Private>
      </Switch>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <SideNav />
      <div>
        <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SearchCustomersProvider>
              <DiscountsProvider>
                <AgentsLogsHistoryProvider>
                  <DiscountCodesLogsProvider>
                    <RithmicAccountsProvider>
                      <FeedProvider>
                        <AppState />
                        <Switch>
                          <Private path="/" exact>
                            {(access_activity_feed_page || read_agents_log) && <Page.Feed />}
                          </Private>

                          <Private path="/helios-admin">
                            {allowed_features.access_to_helios_admin_page && <Page.HeliosAdmin />}
                          </Private>

                          <Private path="/customers">
                            {access_customers_page && <Page.CustomersTabs />}
                          </Private>

                          <Route path="/login">
                            <Page.Login />
                          </Route>

                          <Private path="/financials">
                            <Page.Financials />
                          </Private>

                          {allowed_features.access_rithmic_historical_orders && (
                            <Private path="/rithmicHistoricalOrders">
                              <Page.RithmicHistoricalOrders />
                            </Private>
                          )}

                          <Private path="/agent/:id">
                            <Page.Agent />
                          </Private>

                          <Private path="/agents">
                            <Page.Agents />
                          </Private>

                          <Private path="/affiliate/:id">
                            <Page.Affiliate />
                          </Private>

                          <Private path="/xlsx-customers-data">
                            <Page.ExcelParser />
                          </Private>

                          <Private path="/affiliate/:id">
                            <Page.Affiliate />
                          </Private>

                          <Private path="/affiliates">
                            <Page.Affiliates />
                          </Private>

                          {(access_all_features || allowed_features.access_to_evaluations_tab) && (
                            <Private path="/evaluations">
                              <EvaluationsProvider>
                                <Page.Evaluations />
                              </EvaluationsProvider>
                            </Private>
                          )}

                          <Private exact path="/fundedTraders">
                            <Page.FundedTraders />
                          </Private>

                          <Private exact path="/fundedTraders/:id">
                            <CustomerProvider>
                              <IntercomProvider>
                                <Page.FundedTrader />
                              </IntercomProvider>
                            </CustomerProvider>
                          </Private>

                          <Private exact path="/withdrawalRequests">
                            <Page.Withdrawals />
                          </Private>

                          <Private path="/messages">
                            <Page.Messages />
                          </Private>

                          {(access_to_discounts_tab || access_all_features) && (
                            <Private exact path="/discounts">
                              <Page.Discounts />
                            </Private>
                          )}

                          <Private exact path="/discount/:id">
                            <Page.Discount />
                          </Private>

                          <Private exact path="/journeys">
                            <Page.Journeys />
                          </Private>

                          <Private exact path="/journeys/:id">
                            <Page.Journey />
                          </Private>

                          {access_rithmic_accounts && (
                            <Private exact path="/rithmicAccounts">
                              <Page.RithmicAccounts />
                            </Private>
                          )}

                          <Private path="/customer/:id">
                            {access_customers_page && (
                              <CustomerProvider>
                                <IntercomProvider>
                                  <Page.Customer />
                                </IntercomProvider>
                              </CustomerProvider>
                            )}
                          </Private>

                          {access_to_watchlist_tab && (
                            <Private path="/compliance-watchlist">
                              <Page.ComplianceWatchlist />
                            </Private>
                          )}

                          {access_to_compliance_search_page && (
                            <Private path="/compliance-search">
                              <ComplianceSearchProvider>
                                <Page.ComplianceSearch />
                              </ComplianceSearchProvider>
                            </Private>
                          )}

                          {access_rithmic_sftp_server && (
                            <Private path="/rithmic-files">
                              <Page.RithmicFiles />
                            </Private>
                          )}
                        </Switch>
                      </FeedProvider>
                    </RithmicAccountsProvider>
                  </DiscountCodesLogsProvider>
                </AgentsLogsHistoryProvider>
              </DiscountsProvider>
            </SearchCustomersProvider>
          </MuiPickersUtilsProvider>
        </ErrorBoundary>
      </div>
    </ThemeProvider>
  );
};

export default App;
